.shipping-form-container {
  padding: 10px;
  /* background: #f9f9f9; */
  position: relative;
}

.shipping-form__info-note {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #495057;
  margin-bottom: 16px;
}

.shipping-form__title {
  font-weight: 500;
}

.customer-info__edit {
  display: flex;
  align-items: center;
  gap: 8px;
}

.customer-info__name {
  font-weight: 500;
  font-size: 14px;
}

.customer-info__edit-button {
  border: none;
  color: #0d6efd;
}

.form-content.css-ypiqx9-MuiDialogContent-root {
  overflow-y: scroll !important;
}

.shipping-form__add {
  border: 1px solid rgb(228, 229, 240);
  color: rgb(132, 135, 136);
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 50%;
  padding: 20px 0;
  cursor: pointer;
}
.shipping-form__add p {
  margin: 0;
}

.shipping-form__add:hover {
  border: 1px solid gray;
}

.shipping-form__add-icon {
  font-size: 25px;
}

.shipping-form__customer-info {
  padding-bottom: 20px;
}

.shipping-form__group {
  margin-bottom: 15px;
  font-size: 14px;
}

.shipping-form__control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  z-index: 99;
}

.shipping-form__control:focus {
  outline: 2px solid #007bff;
}

.shipping-form__group .error {
  color: red;
  font-size: 13px;
  margin-top: 5px;
}

.shippshipping-form__submit-button {
  width: 100px;
  margin: 0 auto;
  padding: 6px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 10px;
}
