.carousel-item {
  transition: opacity 1s ease-in-out !important;
}

li {
  transition: opacity 1s ease !important;
}

.custom_input_hire {
  width: 385px;
  height: 45px;
  border-radius: 10px;
  font-size: 18px;
  color: #555;
  font-weight: 400;
}

.btn_submit_hire {
  width: 105px;
  height: 50px;
  border-radius: 10px;
  font-size: 18px;
  color: white;
  background-color: #0459aa;
  transition: all 0.3s;
}

.btn_submit_hire:hover {
  background-color: #09345b;
  color: white;
}

.container_form_search_job {
  /*  */
  background: #15468a;
  padding: 20px 80px;
  justify-content: center;
}

.box_form_search_job {
  padding: 30px;
  border-radius: 10px;
  background: white;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;
}

.container_hot_jobs {
  min-height: 600px;
}

.carousel {
  padding-left: 0px;
  padding-right: 0px;
}

.list_hot_jobs {
  text-align: center;
  height: fit-content;
  padding: 30px 0px 15px 0px;
}

.common_title {
  color: white;
  font-size: 40px;
}

.container_list_jobs {
  padding: 0px 300px;
  padding-bottom: 50px;
}

.box_list_jobs {
  background: #f6f6f6;
  border-radius: 10px;
}

.item_job {
  cursor: pointer;
  border: 1px solid white;
  padding: 10px;
  background-color: white;
  border-radius: 10px;

  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.time_job {
  flex-wrap: nowrap;
  gap: 5px;
}

.time_job_2 {
  flex-wrap: wrap;
  gap: 5px;
}

.info_basic_company span {
  cursor: pointer;
  display: block;
  line-height: 23px;
  width: fit-content;
}

.name_job_company {
  font-size: 22px;
  font-weight: 500;
  color: var(--primary-blue);
}

.name_company_hire {
  font-size: 16px;
  font-weight: 300;
  color: #cdabab;
}

.text_time_job {
  padding: 3px 6px;
  font-size: 12px;
  background: #e9eaec;
  border-radius: 5px;
  white-space: nowrap;
}

.image.is-5by3 img,
.image.is-5by4 img {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.image {
  display: block;
  position: relative;
}

.image img {
  display: block;
  height: auto;
  width: 100%;
}

.image.is-5by4 {
  padding-top: 90%;
}

.box_img_logo_company {
  border: 1px solid #e9eaec;
  border-radius: 8px;
}

.box_img_logo_company img {
  object-fit: contain;
}

.image.is-5by3 {
  padding-top: 105%;
}

@media (min-width: 768px) {
  .col-md-3-custom {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-9-custom {
    flex: 0 0 auto;
    width: 75%;
  }
}

.salary,
.btn_apply {
  text-align: end;
}

.text_salary {
  font-size: 17px;
  color: var(--primary-blue);
  font-weight: 500;
}

.apply_hire_job {
  cursor: pointer;
  display: inline-block;
  padding: 4px 20px;
  border-radius: 5px;
  background: var(--primary-blue);
  font-size: 15px;
  color: white;
  font-weight: 500;
}

.item_job:hover {
  border: 1px solid var(--primary-blue);
}

.common_title {
  background-image: linear-gradient(to right,
      var(--primary-color),
      var(--primary-color) 50%,
      white 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

.common_title:before {
  content: "";
  background: var(--primary-color);
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.common_title:hover {
  background-position: 0;
}

.common_title:hover::before {
  width: 100%;
}

.btn_show_more {
  font-size: 18px;
  font-weight: 500;
  padding: 5px 10px;
  background: var(--primary-blue);
  border-radius: 5px;
  color: white !important;
  transition: 0.2s all;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
}

.btn_show_more:hover {
  background: var(--primary-color);
}

.box_show_more {
  margin-top: 10px;
  text-align: center;
}

.we_hire {
  background: url("../../../../../public/image/hire.png") no-repeat center;
  min-height: 90px;
}

.container_why_company {
  background: url("../../../../../public/image/rs.png") no-repeat center;
  min-height: 90px;
}

.box_introduction_company_2 {
  /* background: url("../../../../../public/image/bg_global_new.png") no-repeat
    center/cover; */
  min-height: 750px;
}

.box_reward_benefit,
.container_list_image,
.box_company_2,
.box_introduction_company {
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
}

.box_text_nguyen_kim h2 {
  font-size: 38px;
  color: #4a5472;
  font-family: "Open Sans", Arial, Tahoma, sans-serif;
  letter-spacing: 0.04em;
  font-weight: 500;
}

.box_text_nguyen_kim {
  margin-top: 45px;
}

.text_name_company {
  padding: 30px 0px 20px;
  font-size: 36px;
  font-family: "Open Sans", Arial, Tahoma, sans-serif;
  color: #4a5472;
  font-weight: 600;
}

.description_compay {
  color: #777;
  font-size: 16px;
  letter-spacing: 1.5px;
}

.text_culture {
  display: inline-block;
  width: fit-content;
  padding: 0px 135px;
}

.container_reward_benefit {
  /* background: url("../../../../../public/image/bg_benefit.png") no-repeat
    center/cover; */
  min-height: 700px;
  padding: 50px 0px;
}

.content_reward_benefit {
  max-width: 960px;
  margin: 0 auto;
}

.box_image_reward_item {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 50%;
  border: 1px solid white;
  overflow: hidden;
  display: inline-block;
}

.box_image_reward_benefit {
  margin-top: 50px;
}

.box_item_reward {
  padding: 60px 0px;
  transition: all 0.3s;
  background: white;
  border-radius: 10px;
}

.paren_image_reward_item {
  width: fit-content;
  display: inline-block;
  padding: 10px;
  border-radius: 50%;
  border: 1px solid white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.parent_image_item {
  padding: 0px 110px;
}

.image_reward {
  object-fit: contain;
}

.box_title_reward_item span {
  font-family: "Open Sans", Arial, Tahoma, sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: #697492;
}

.box_title_reward_item {
  margin-top: 10px;
}

.box_item_reward:hover {
  background-color: var(--primary-color);
}

.box_item_reward:hover .box_image_reward_item {
  background-color: white;
}

.box_item_reward:hover .box_title_reward_item span {
  color: white;
}

.iconpoint::after {
  content: url(../../../../../public/image/line_we_hire.png);
  height: 20px;
  position: absolute;
  left: 57%;
  top: 10px;
}

.iconpoint {
  position: relative;
}

.icon-hire {
  height: 90px;
}

.item_process_hire {
  display: inline-block;
  text-align: center;
}

.text_process_step {
  display: block;
  font-size: 21px;
  font-weight: bold;
  color: #616f81;
}

.text_process_title {
  font-size: 14px;
  font-weight: bold;
  color: #4a5472;
  letter-spacing: normal;
  text-align: center;
  font-style: normal;
  /* padding: 0px 58px; */
}

.box_text_process {
  padding: 0px 20px;
}

.container_introduction_company {
  padding: 80px 0px;
}

@media (max-width: 600px) {
  .text_culture {
    padding: 0px;
  }

  .custom_input_hire {
    width: 100% !important;
  }

  .iconpoint::after {
    display: none;
  }

  .image.is-5by4 {
    padding-top: 50% !important;
  }

  .time_job {
    flex-wrap: wrap;
  }

  .container_list_jobs {
    padding: 50px 10px;
  }

  .container_form_search_job {
    padding: 20px 10px;
  }

  .box_btn_submit_hire {
    text-align: center;
  }

  .common_title {
    font-size: 28px;
  }

  .box_text_nguyen_kim h2 {
    font-size: 20px;
  }

  .container_why_company {
    background-size: contain;
  }

  .text_name_company {
    font-size: 20px;
    text-align: center;
  }

  .box_form_search_job {
    padding: 20px 10px;
    flex-wrap: wrap;
  }

  .box_card_item_job {
    padding: 0px !important;
  }

  .item_job {
    padding: 3px;
  }

  .box_introduction_company_2 {
    min-height: 500px;
  }

  .description_compay {
    font-size: 15px;
  }

  .item_image_reward_benefit {
    margin: 3px 0px;
    padding: 0px 5px;
  }

  .box_item_reward {
    padding: 15px 0px;
  }

  .image.is-5by3 {
    padding-top: 85%;
  }

  .parent_image_item {
    padding: 15px 30px;
  }

  .box_title_reward_item {
    margin-top: 0px;
  }

  .box_title_reward_item span {
    display: block;
    font-size: 13px;
  }

  .item_process_hire {
    margin: 10px 0px;
  }

  .carousel-item {
    height: 100%;
  }
}

@media (min-width: 1281px) and (max-width: 1599px) {
  .container_list_jobs {
    padding: 0px 230px;
  }
}

@media (min-width: 961px) and (max-width: 1281px) {
  .container_form_search_job {
    padding: 20px 10px;
  }

  .container_list_jobs {
    padding: 0px 70px;
  }
}

@media (min-width: 600px) and (max-width: 961px) {
  .iconpoint::after {
    display: none;
  }

  .box_form_search_job {
    padding: 10px 15px !important;
    align-items: center;
    flex-wrap: wrap;
  }

  .container_list_jobs {
    padding: 20px;
  }

  .container_form_search_job {
    padding: 20px;
  }

  .custom_input_hire {
    width: 330px;
  }

  .parent_image_item {
    padding: 0px 100px;
  }

  .box_item_reward {
    padding: 20px 0px;
  }

  .item_image_reward_benefit {
    margin: 7px 0px;
  }

  .parent_image_item {
    padding: 0px 120px;
  }

  .text_time_job {
    white-space: normal;
  }

  .time_job {
    flex-wrap: wrap;
  }
}