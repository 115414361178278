.custom-toast {
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #333;
  font-size: 15px;
  font-weight: 500;
  border-radius: 4px;
  padding: 16px 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;

  /* Animation */
  opacity: 0;
  transform: translateY(-20px);
  animation: toastAnimation 0.3s ease-in-out forwards;
}

.toast-icon {
  margin-right: 10px;
}

.toast-content {
  flex-grow: 1;
}

.toast-close {
  background: none;
  border: none;
  cursor: pointer;
  color: #999;
  margin-left: 10px;
}

.toast-close:hover {
  color: black;
}

@keyframes toastAnimation {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .custom-toast {
    padding: 8px 16px;
  }

  .toast-close {
    margin-left: 0;
  }
}
