.product-detail-model {
  margin-top: 1rem;
  width: 100%;
}

.product-detail-container {
  display: flex;
}

.product-detail-right {
  height: fit-content;
  width: 70%;
}

.product-detail-left {
  margin-left: 8px;
  width: 28%;
  height: fit-content;
  position: sticky;
  top: 140px;
}

.css-iljtu2-MuiRating-root {
  font-size: 2.5rem !important;
}

.product-detail-left.fixed {
  position: fixed;
  right: 58px;
}

.product-detail-left.end {
  position: absolute;
  bottom: calc(-100vh * 2.25);
  right: 58px;
}

.product-detail-header {
  display: flex;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
}

.product-img-detail-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 50px;
  border-radius: 4px;
}

.product-img-detail {
  max-width: 100%;
  max-height: 100%;
  position: relative;
}

.product-mark {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 2;
}

.product-mark img {
  width: 100%;
}

.product-img-slide {
  display: flex;
  overflow-x: auto;
  width: 100%;
  height: 80px;
}

.product-img-slide-item {
  margin-right: 5px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  border: 1px solid #000;
  background-position: center;
}

.product-img-slide-item.active {
  border: 1px solid blue;
}

.price-product {
  font-size: 24px;
  font-weight: 700;
  color: var(--primary-red);
}

.price-market span {
  font-size: 15px;
  font-weight: 500;
}

.price-retail p {
  font-size: 12px;
  line-height: normal;
  color: #82869e;
}

.price-old-product {
  font-size: 14px;
  color: #888;
  text-decoration: line-through;
  font-weight: 600;
}

.vat-title {
  float: right;
  color: #888;
  font-size: 14px;
  font-weight: 600;
}

.label-title {
  font-size: 15px;
  margin-bottom: 0;
  font-weight: 600;
  color: #444;
}

.label-content {
  font-size: 15px;
  margin-bottom: 0;
  margin-left: 5px;
}

.star {
  color: var(--primary-color);
}

.describe {
  color: var(--primary-red);
}

.label-content.code {
  font-size: 15px;
  margin-bottom: 0;
  margin-left: 5px;
  color: var(--primary-blue);
}

.label-content.goods {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 5px;
  color: green;
}

.label-content.Nogoods {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 5px;
  color: var(--primary-red);
}

.btn-compare {
  /* width: 30%; */
  height: 45px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--primary-color);
  color: var(--primary-blue);
  font-size: 15px;
  font-weight: 700;
}

.product-image-slide {
  width: 30rem;
}

/* .splide__slide img {
    width: 100%;
    height: auto;
  } */
.product-detail-center {
  padding: 0 20px 0 20px;
  width: 70%;
}

.btn-box {
  margin-top: 10px;
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  justify-content: space-around;
  align-items: center;
}

.btn-box-add-cart {
  /* width: 43%; */
  height: 45px;
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  background-color: var(--primary-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-box-buy-now {
  /* width: fit-content; */
  height: 45px;
  padding: 0 10px;
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  border-radius: 5px;
  background-color: var(--primary-red);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-box-buy-now:hover {
  color: #fff;
}

.detailed-specificate-turn-on {
  display: none;
}

.product-img-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  cursor: pointer;
  /* padding: 50px; */
  position: relative;
  background: #f0f0f0;
}

.techInfo {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  bottom: 10px;
}

.product-hot-logo-detail {
  position: absolute;
  width: 150px;
  top: -5%;
  left: -5%;
}

.product-detail-name-product {
  font-weight: 600;
  font-size: 19px;
}

.product-img-detail-slide {
  height: 300px;
  object-fit: cover;
  position: relative;
}

.product-mark-detail {
  position: absolute;
  z-index: 3;
  height: 100px;
  width: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.product-mark-detail img {
  width: 100%;
  height: 100% !important;
}

.partner-imgs {
  flex-wrap: nowrap;
  gap: 2px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: var(--primary-blue);
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.close-model-img {
  width: 50px !important;
  cursor: pointer;
  z-index: 1555;
}

.product-detail-img-desc .modal-dialog {
  margin: 15px auto;
  min-width: fit-content;
}

.product-detail-img-desc .modal-dialog .modal-content {
  transform: translateY(4vh);
  width: 60vw;
  height: auto;
}

.point {
  transform: rotateZ(90deg);
  margin-right: 5px;
}

.Notification {
  font-size: 14px !important;
  display: flex;
  align-items: center;
}

.customer-reviews .css-ryrseu-MuiRating-root {
  font-size: 2.5rem !important;
}

.product_desc div {
  font-size: 14px;
  margin: 8px 0;
}

.no-product-container {
  margin-top: 15rem;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  width: 100%;
  justify-content: center;
  min-height: 64vh;
  align-items: center;
  padding: 10px 15px;
}

.copy-btn {
  font-size: 14px;
  color: var(--primary-blue);
  cursor: pointer;
}

.copy-btn:hover {
  opacity: 0.6;
}

.product-detail-hotline {
  width: 100%;
  padding: 8px;
  background-color: #4a90e2;
  border-radius: 4px;
  margin-top: 10px;
}

.product-detail-hotline__message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
}

.product-detail-hotline__message img {
  max-width: 50px;
}

.product-detail-hotline__message p {
  font-size: 16px;
  font-weight: 600;
}

.product-detail-hotline__form form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}

.product-detail-hotline__form form input {
  padding: 3px;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  max-width: 70%;
  flex-grow: 1;
  margin-right: 4px;
}

.product-detail-hotline__form form input:focus {
  outline: none;
}

.product-detail-hotline__form form button {
  padding: 3px;
  color: white;
  background-color: orangered;
  font-size: 14px;
  border: none;
  border-radius: 3px;
  width: 60px;
}

.product-detail__status {
  font-size: 15px;
  margin-top: 4px;
}

.product-detail__status :last-child {
  color: var(--primary-red);
}

/* mobile */

@media only screen and (max-width: 767px) {
  .partner-imgs {
    flex-wrap: wrap;
  }

  .partner-imgs-right {
    margin-left: 0px;
    margin-right: 0px;
  }

  .partner-news-body__title {
    font-size: 14px;
  }

  .secondary_main {
    padding-left: 0px;
    padding-right: 0px;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    color: var(--primary-blue);
  }

  .mySwiper2 {
    height: 80%;
    width: 100%;
  }

  .close-model-img {
    width: 45px !important;
    cursor: pointer;
    z-index: 1555;
  }

  .mySwiper {
    margin: 26px auto;
    justify-content: center;
  }

  .product-detail-img-desc .modal-dialog {
    margin: 15px auto;
    min-width: fit-content;
  }

  .product-detail-img-desc .modal-dialog .modal-content {
    transform: translateY(10vh);
    width: 100vw;
    height: auto;
  }

  .product-detail-header {
    display: block;
  }

  .price-product {
    font-size: 18px;
    font-weight: 700;
    color: var(--primary-red);
  }

  .product-detail-container {
    display: block;
  }

  .detailed-specificate-turn-on {
    margin-top: 10px;
    display: block;
  }

  .product-image-slide {
    width: 100%;
  }

  .product-detail-center {
    margin-top: 10px;
    padding: 0;
    width: 100%;
  }

  .warranty-policy {
    display: none;
  }

  .product-detail-right {
    width: 100%;
  }

  .product-detail-left {
    display: none;
  }

  .btn-box {
    /* margin-top: 8rem; */
    display: block;
  }

  .btn-box-add-cart {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    background-color: var(--primary-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .btn-box-buy-now {
    width: 100%;
    height: 45px;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: var(--primary-red);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .btn-compare {
    width: 100%;
    height: 45px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--primary-color);
    color: var(--primary-blue);
    font-size: 16px;
    font-weight: 700;
  }
}

/* tablet */
@media only screen and (min-width: 767px) and (max-width: 1023px) {


  .product-detail-header {
    display: block;
  }

  .product-detail-container {
    display: block;
  }

  .detailed-specificate-turn-on {
    margin-top: 10px;
    display: block;
  }

  .product-image-slide {
    width: 50%;
    margin: 0 auto;
  }

  .product-detail-center {
    margin-top: 10px;
    width: 100%;
  }

  .warranty-policy {
    display: none;
  }

  .product-detail-right {
    width: 100%;
  }

  .product-detail-left {
    display: none;
  }

  .btn-box {
    /* margin-top: 8rem; */
    display: block;
  }

  .btn-box-add-cart {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    background-color: var(--primary-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .btn-box-buy-now {
    width: 100%;
    height: 45px;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: var(--primary-red);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .btn-compare {
    width: 100%;
    height: 45px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--primary-color);
    color: var(--primary-blue);
    font-size: 16px;
    font-weight: 700;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    color: var(--primary-blue);
  }

  .mySwiper2 {
    height: 80%;
    width: 100%;
  }

  .close-model-img {
    width: 45px !important;
    cursor: pointer;
    z-index: 1555;
  }

  .mySwiper {
    margin: 26px auto;
    justify-content: center;
  }

  .product-detail-img-desc .modal-dialog {
    margin: 0 auto;
    min-width: fit-content;
  }

  .product-detail-img-desc .modal-dialog .modal-content {
    transform: translateY(5vh);
    width: 60vw;
    height: auto;
  }
}

/* desktop */
@media only screen and (min-width: 1024px) {}