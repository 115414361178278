.warranty-policy {
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #fff;
}

.warranty-policy-title {
    background-color: var(--primary-color);
    color: var(--primary-red);
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-left: 5px;
    height: 100%;
    border-radius: 5px;
}

.warranty-policy-content {
    padding: 2px 8px;
    font-size: 14px;
    color: var(--primary-red);
    font-weight: 600;
    display: flex;
    flex-direction: column;
}

.warranty-policy-content-item {
    margin: 4px 5px;
    color: #0d85fe;
}

.warranty-policy-content-item:hover {
    color: var(--primary-red);
}