.product-desc-container {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.height-450 {
  max-height: 300px;
}

.product-desc-img {
  width: 100%;

  border-radius: 5px;
}

.product-desc-title {
  font-size: 18px;
  font-weight: 700;
  color: var(--primary-red);
}

.product-desc-content {
  font-size: 16px;
}

/* .product-desc-content div img {
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
} */

.product-desc-content h1 {
  font-size: 24px;
}

.product-desc-content h2 {
  font-size: 20px;
}

.product-desc-content h3 {
  font-size: 18px;
}

.product-desc-see-more {
  width: 100%;
  height: 60px;
  font-size: 14px;
  color: var(--primary-blue);
  padding-top: 8px;
  font-weight: 700;
  cursor: pointer;
  background: linear-gradient(
    to bottom,
    rgba(255 255 255/0),
    rgba(255 255 255/62.5),
    rgba(255 255 255/1)
  );
  bottom: 0;
}

.see-more-text {
  margin: 0;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 6px 13px;
  width: fit-content;
  height: fit-content;
  border: 1px solid;
}
