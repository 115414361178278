.consultant-container {
  margin-top: 32px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px 15px 30px 15px;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
}

.consultant-category {
  display: flex;
  align-items: center;
  justify-content: center;
}

.consultant-category ul {
  display: flex;
  gap: 20px;
}

.consultant-category li {
  list-style-type: none;
  padding: 4px 16px;
  border: 1px solid #ccc;
  font-size: 15px;
  color: var(--text-gray);
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  transition: color 0.3s ease;
}

.consultant-category li:hover,
.consultant-box button:hover {
  background-color: var(--primary-blue);
  color: #fff;
  border: var(--primary-blue);
  cursor: pointer;
}

.consultant-category li::before {
  content: "";
  position: absolute;
  top: 0;
  left: -75%;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: skewX(-25deg);
  transition: all 0.5s;
}

.consultant-category li:hover::before {
  left: 125%;
  transition: left 0.5s ease;
}

.consultant-container h2 {
  position: relative;
  text-align: center;
  font-size: 28px;
  color: var(--primary-blue);
  font-weight: 600;
  letter-spacing: -1px;
  margin: 28px 0;
}

.consultant-container h2::after {
  content: "";
  display: block;
  width: 10%;
  height: 3px;
  background: var(--primary-color);
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
}

.consultant-box {
  background-image: url(../../../../component/image/bg-consultant.jpg);
  background-clip: content-box;
  background-origin: content-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 250px;
  padding: 30px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.consultant-box p {
  font-size: 14px;
  max-width: 600px;
  color: var(--text-gray);
}

.consultant-box button {
  margin-top: 20px;
  padding: 10px 48px;
  background-color: var(--primary-color);
  font-size: 20px;
  border: none;
  color: var(--text-gray);
  border-radius: 5px;
  transition: color 0.3s ease;
}

@media only screen and (max-width: 739px) {
  .consultant-category ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .consultant-container {
    padding-left: 0 !important;
  }

  .question-icon {
    flex-shrink: 0;
  }
}
