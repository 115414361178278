.partner-container {
  background-color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
}

.partner-title {
  background-color: var(--primary-color);
  color: var(--primary-red);
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 5px;
  height: 40px;
  border-radius: 5px;
}

.partner-introduce,
.partner-thanks {
  margin-top: 20px;
}

.partner-introduce>p,
.partner-thanks {
  font-size: 16px;
}

.partner-logo__title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  color: #0d6efd;
}

.partner-logo__item {
  text-align: center;
  font-size: 24px;
}

.box_partner_company {
  margin-top: 1rem;
}

.logo-grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0px;
  padding: 0 48px;
  margin-top: 20px;
}

.logo-grid-item {
  height: 120px;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  cursor: pointer;
  padding: 16px;
}

.logo-grid-item:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  transition: all ease 300;
}

.logo-grid-item>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media only screen and (max-width: 481px) {
  .logo-grid-container {
    grid-template-columns: repeat(3, 1fr);
    padding: 0 16px;
    gap: 16px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 641px) {
  .logo-grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}