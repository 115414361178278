@media only screen and (max-width: 739px) {
  .top_header {
    display: none;
  }

  .shopping_mobile {
    padding-top: 0px;
  }

  .nk_image {
    width: 140px;
    margin-top: 0px;
    margin-left: 10px;
  }

  .list {
    display: none;
  }

  .header-container {
    border-radius: 0% 0% 50% 50%;
  }

  .list__item {
    margin-bottom: 2%;
  }

  .shopping {
    display: none;
  }

  .header_main_ipad {
    display: none;
  }

  .search {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }



  .changeHeader {
    height: 8rem !important;
    border-radius: 0px !important;
  }

  .drum {
    display: block;
  }

  .disable-block {
    display: none;
  }
}

@media only screen and (min-width: 740px) and (max-width: 1023px) {
  .top_header {
    display: none;
  }

  .header_menu {
    display: none;
  }

  .nk_image {
    width: 130px;
    margin-left: 25px;
  }

  .shopping {
    margin-top: 35px;
  }

  .search {
    margin-top: 18px;
  }

  .shopping_mobile {
    display: none;
  }

  .header_main_ipad {
    justify-content: space-between;
    display: flex;
    font-size: 12px;
    margin-top: 5px;
    font-weight: 700;
    cursor: pointer;
    color: #fff;
    align-items: center;
  }

  .list__item {
    margin-bottom: 2%;
    text-align: center;
  }

  .shopping {
    margin-top: 35px;
    margin-left: -25px;
  }

  .search {
    width: 70%;
    margin: 0 30px;
  }
}

@media only screen and (min-width: 1024px) {
  .header_menu {
    display: none;
  }

  .nk_image {
    width: 140px;
  }

  .shopping_mobile {
    display: none;
  }

  .shopping {
    display: inline;
  }

  .header_main_ipad {
    display: none;
  }
}