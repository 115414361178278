.product-hot-page-container-img {
    width: 100%;
}

.product-hot-page-top-flash-sale {
    margin-top: 40px;
}

.product-hot-page-top-flash-sale-content {
    background-image: url("../../../../component/image/product-hot-bg.jpg");
    background-position: center;
    background-size: cover;
    background-clip: content-box;
    background-origin: content-box;
    background-repeat: no-repeat;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: auto;
    border-radius: 5px;
}

.product-hot-item {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: auto;
    border-radius: 5px;
    padding: 15px 10px 10px 10px;
}

.load-more-btn {
    width: max-content;
    margin: 0px auto;
    padding: 0 15px;
    text-align: center;
    height: 40px;
    line-height: 42px;
    border-radius: 4px;
    border: 1px solid var(--primary-blue);
    color: var(--primary-blue);
    font-weight: 700;
    font-size: 16px;
    background-color: #fff;
    cursor: pointer;
}

.load-more-btn:hover {
    opacity: 0.7;
}