 .product-comparison-tr {
   border-width: 1px;
 }

 .product-comparison-td,
 .product-comparison-th {
   padding: 10px;
   border: 1px solid #c9c5c5 !important;
   min-width: 200px;
   text-align: center;
 }

 .product-comparison-container {
   margin-top: 1rem;
 }

 .product-comparison-title {
   font-size: 22px;
   margin-bottom: 12px;
   font-weight: 600;
   text-align: center;
 }

 .products-4 {
   margin: 0 auto;
 }

 .products-4 th,
 .products-4 td {
   width: 25%;
 }

 .product-comparison-th {
   background: #eee;
   top: -2px;
 }

 .product-comparison-th img {
   height: 0;
   margin: 0 auto;
   display: block;
   transition: height 200ms ease-in-out;
 }

 .sticky img {
   height: 100px;
 }

 .label {
   font-size: 14px;
   font-weight: bold;
   text-align: left;
   min-width: 150px;
   width: 150px;
   border: 1px solid #c9c5c5;
   background: #eee;
 }

 .spec {
   font-size: 14px;
 }

 .cat-option-list {
   display: flex;
 }

 .heading {
   display: none;
 }

 @media only screen and (max-width: 767px) {
   .label {
     display: none;
   }

   .product-comparison-td,
   .product-comparison-th {
     padding: 10px;
     border-width: 1px;
     border-color: #c9c5c5;
     min-width: unset;
     text-align: center;
   }

   .heading {
     display: revert;
     background: #ccc;
     width: 100%;
     text-align: left;
     position: relative;
   }

   .heading span {
     position: sticky;
     top: 0;
     font-size: 14px;
     font-weight: 700;
     left: 10px;
   }
 }

 @media only screen and (min-width: 767px) and (max-width: 1023px) {}

 @media only screen and (min-width: 1024px) {}