.product-viewed {
  top: 85%;
  right: 25px;
  z-index: 99;
  position: fixed;
  transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  /* width: calc((100% - 1170px) / 2); */
}

.zalo-img {
  width: 40px;
  border-radius: 10px;
  cursor: pointer;
  margin: 5px 10px 5px 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.member-support-container {
  top: 90%;
  right: 25px;
  z-index: 99;
  position: fixed;
  background-color: #fff;
  color: var(--primary-red);
  font-size: 14px;
  padding: 5px 6px;
  font-weight: 500;
  border-radius: 16px;
  width: 132px;
  cursor: pointer;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.member-support-name {
  font-size: 18px;
  text-align: center;
  /* margin-bottom: 5px; */
  font-weight: 700;
  color: var(--primary-red);
}

.member-support-item {
  display: flex;
  align-items: center;
}

.member-support-item-desc {
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.member-support-item-desc:hover {
  color: var(--primary-red);
}

.close-modal-btn {
  font-size: 26px;
  position: absolute;
  top: 15px;
  cursor: pointer;
  right: 31px;
}

.close-modal-btn:hover {
  opacity: 0.7;
}

.css-63vwl1-MuiTypography-root-MuiBreadcrumbs-root {
  font-size: 14px !important;
}

/* css đóng góp ý kiến*/
.content {
  text-align: center;
  font-weight: 700;
  margin-top: 10px;
  font-size: 18px;
}

.link-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.link:hover {
  color: var(--primary-red);
}

@media only screen and (max-width: 739px) {
  .product-viewed {
    top: 82%;
    left: 10px;
    z-index: 99;
    position: fixed;
    transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    width: calc((100% - 1170px) / 2);
  }

  .member-support-container {
    display: none;
  }
}

@media only screen and (min-width: 740px) and (max-width: 1023px) {
  .product-viewed {
    top: 88%;
    left: 25px;
    z-index: 99;
    position: fixed;
    transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    width: calc((100% - 1170px) / 2);
  }
}


.text_genaral_two_line_2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.text_genaral_two_line_1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}


.container_breadcrumb_new li {
  white-space: nowrap;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  width: auto;
}

.container_breadcrumb_new ol {
  flex-wrap: nowrap;
}

.box-popup-parameter-card {
  max-width: 350px;
}