.product-promotion-title {
    font-size: 20px !important;
    font-weight: 700 !important;
}

.product-promotion-content-title {
    font-size: 12px;
    font-weight: 700;
    color: rgb(130, 134, 158);
    text-transform: uppercase
}

.product-promotion-content-container {
    background-color: #f6f6f6;
    padding: 6px 22px;
    border-radius: 5px;
}

.product-promotion-item {
    width: 100%;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid var(--primary-red);
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.product-promotion-icon-gift {
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: var(--primary-red);
    height: 70px;
    background-color: rgb(243, 243, 247);
    font-size: 20px;
}

.product-promotion-content {
    margin-top: 10px;
}

.product-promotion-item-name {
    width: 100%;
    padding: 4px 12px;
}

.product-promotion-item-name p {
    font-size: 12px;
}



.close-product-promotion-model {
    border: 1px solid #444;
    padding: 8px 18px;
    font-size: 12px;
    cursor: pointer;
    font-weight: 700;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-right: 20px;
}

.close-product-promotion-model:hover {
    opacity: 0.7;
}

.cancel-btn {
    font-weight: 600;
    color: var(--primary-red);
    cursor: pointer;
}

.cancel-btn:hover {
    opacity: 0.7;
}

@media only screen and (max-width: 767px) {
    .product-promotion-content-container {
        background-color: #f6f6f6;
        padding: 6px 5px;
        border-radius: 5px;
    }
}

@media only screen and (min-width: 740px) and (max-width: 1023px) {}

@media only screen and (min-width: 1024px) {}