@media only screen and (max-width: 739px) {
  .footer {
    padding-top: 5px;
  }

  .footer-container-destop {
    display: none;
  }
}
@media only screen and (min-width: 740px) and (max-width: 1023px) {
  .footer {
    padding-top: 25px;
  }

  .footer-phone {
    display: inline;
  }
  .footer-container-destop {
    display: none;
  }
  .footer-phone-main {
    padding-bottom: 50px;
  }
}
@media only screen and (min-width: 1024px) {
  .footer-phone {
    display: none;
  }
  .footer-container-destop {
    display: inline;
  }
}
