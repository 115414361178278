.product-viewed-container {
    background-color: #fff;
    color: var(--primary-blue);
    font-size: 14px;
    padding: 5px 6px;
    font-weight: 500;
    border-radius: 16px;
    width: 132px;
    cursor: pointer;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.product-viewed-container:hover {
    color: var(--primary-red);
}

.product-viewed-container-modal-title {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
}

.viewed-product-img {
    width: 100% !important;
    border-radius: 8px;
    height: 100%;
    background-size: cover;
    object-fit: contain;
    background-position: center;
    background-clip: content-box;
    background-origin: content-box;
    border: 0.5px solid silver;
}

.viewed-product-item-left {
    height: 110px;
}


.viewed-product-item {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #cbcbcb;
    margin: 5px 0px;
}



.viewed-product-item-content-title {
    font-size: 14px;
    color: #000;
    font-weight: bold;
    display: -webkit-box;
    margin-bottom: 0px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    cursor: pointer;
    line-height: normal;
}

/* .viewed-product-item-content-title:hover {
    color: var(--primary-red);
} */

.product-viewed-container-content {
    max-width: 60rem;
    height: 30rem;
    margin-bottom: 20px;
}

.viewed-product-item-content-price {
    color: var(--primary-red);
    cursor: pointer;
    font-size: 16px;
    float: left;
    width: 100%;
    font-weight: bold;
    display: -webkit-box;
    /* max-height: 3rem; */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 1;
    /* line-height: 3rem; */
}

.product-viewed-container-modal-del-history {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    color: var(--primary-red);
}