.related-product-container {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 5px;
}

.related-product-title {
  color: var(--primary-red);
  font-size: 18px;
  font-weight: 600;
}

.realated-product-box {
  margin-top: 10px;
  margin-bottom: 10px;
}

.realated-product-content .react-multi-carousel-item {
  padding: 0 4px;
}

.realated-product-box-container .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item {
  margin-right: 20px;
  min-width: 184px;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  list-style-type: none;
  float: left;
  /* min-width: 172px;
        transform-style: preserve-3d;
        backface-visibility: hidden; */
}

@media only screen and (max-width: 767px) {
  .carousel-indicators {
    bottom: -36px;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    font-size: 20px;
    background-color: red;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .realated-product-box {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 10px;
    min-width: unset;
  }

  .realated-product-box-container .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item {
    min-width: 172px;
    margin-right: 0;
    transform-style: preserve-3d;
    backface-visibility: hidden;
  }
}