.detailed-specificate-model-container.detailed-specificate-model-container-detail {
    min-width: 40rem;
    height: 82vh;
}

.detailed-specificate-col-title-main-model {
    font-size: 14px !important;
    width: 100px;
    padding: 10px !important;
    color: rgb(130, 134, 158) !important;
}

.detailed-specificate-col-title-model {
    font-size: 14px !important;
    padding: 10px !important;
}


.detailed-specificate-model-content.detailed-specificate-model-content-detail {
    height: 70vh;
}
.detailed-specificate-model-content {
    display: block;
}

.detailed-specificate-model-content-moblie {
    display: none;
}

.detailed-specificate-model-table.detailed-specificate-model-table-detail {
    padding: 10px 20px;
}

@media only screen and (max-width: 767px) {
    .detailed-specificate-model-container.detailed-specificate-model-container-detail {
        min-width: 80vw;
        height: auto;
    }
    .detailed-specificate-model-content {
        display: none;
    }

    .detailed-specificate-model-content-moblie {
        display: block;
        height: 68vh;
        overflow-y: scroll;
    }

    .detailed-specificate-col-title-main-model {
        font-size: 12px !important;
        width: 100px;
        padding: 10px !important;
        color: rgb(130, 134, 158) !important;
    }
    
    .detailed-specificate-col-title-model {
        font-size: 12px !important;
        padding: 10px !important;
    }
} 
