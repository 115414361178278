.stickcompare {
    width: 100%;
    max-width: 400px;
    margin: auto;
    position: fixed;
    left: 0;
    right: 0;
    border-radius: 10px;
    top: 10%;
    z-index: 100;
    background: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, .12);
}

.stickcompare.stickcompare_new {
    max-width: 1178px;
    top: unset;
    bottom: 0;
    border-radius: 0;
    border-top: 1px solid #e5e5e5;
}

/* .productComparisonContent {
    min-width: 50rem;
    min-height: 40rem;
} */

.productComparisonTitle {
    text-align: center;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.box_body_compare span {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Chỉ hiển thị tối đa 2 dòng */
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* Ẩn nội dung thừa */
    text-overflow: ellipsis;
    /* Hiển thị dấu "..." nếu text vượt quá */
}


.productComparisonItems {
    width: 12rem;
    height: 170px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    margin: 5px 0px;
    padding: 2px 4px;
}

.img-select {
    width: 100px;
}

.addProductComparison {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 12rem;
    height: 170px;
    margin: 5px 0px;
    padding: 2px 4px;
}

.model-comparison-title {
    text-align: center;
}

.find-sp {
    position: relative;
    padding: 0 10px;
}

.find-sp input {
    display: block;
    overflow: hidden;
    width: 100%;
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    padding: 10px 0 10px 30px;
    box-sizing: border-box;
    outline: none;
}

.btn-comparison {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: var(--primary-blue);
    font-size: 15px;
    font-weight: 700;
    margin: 0;
    padding: 5px 0;
    border: 1px solid #d0d0d0;
    border-radius: 4px;
}

.box-item-choose {
    display: flex;
    gap: 10px;
}

.icon-search {
    position: absolute;
    top: 12px;
    left: 17px;
    color: #999;
    cursor: pointer;
}

.choose-title {
    display: -webkit-box;
    max-height: 2.8rem;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    line-height: 1.6rem;
    margin-top: 10px;
}

.text-submit {
    margin: 0;
}

.icon-add {
    border: 1px dashed #999;
    border-radius: 5px;
    padding: 3px;
}

.productComparisonItem {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

/* .productComparison-select-box {
    margin-top: 10px;
    padding: 0 10px;
} */

.brand-option {
    font-size: 14px;
}

.search-product-by-brand {
    position: relative;
}

.search-brand-result {
    position: absolute;
    background-color: #fff;
    z-index: 1;
    max-height: 30rem;
    overflow-y: scroll;
    width: 96%;
    border: 1px solid #ddd;
}

.search-brand-img-box {
    justify-content: center;
    min-width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
}

.search-brand-img {
    width: 100% !important;
    background-size: cover;
    height: 80%;
}

.search-brand-result-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 15px;
    border-bottom: 1px solid #888;
}

.search-brand-result-item-left {
    display: flex;
}

.search-brand-product-content {
    margin-left: 5px;
}

.search-brand-product-content-title {
    font-size: 12px;
    font-weight: 600;
    display: -webkit-box;
    min-height: 3rem;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    line-height: 1.6rem;
}

.search-brand-product-content-price {
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-red);
}

.search-brand-result-icon-search {
    border: 1px solid var(--primary-blue);
    padding: 2px;
    border-radius: 5px;
    cursor: pointer;
}

.result-icon-search {
    color: var(--primary-blue);
}

.delete-productComparisonItems-btn {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 15px;
}

@media only screen and (max-width: 767px) {
    .productComparisonItem {
        display: block;
    }

    .productComparisonItem {
        display: block;
    }

    .box {
        display: flex;
        justify-content: center;
    }

    .box-item-choose {
        display: flex;
        padding: 0 16px;
        width: 100%;
        overflow-x: scroll;
    }

    .box-item-choose::-webkit-scrollbar {
        display: none;
    }

    .css-ypiqx9-MuiDialogContent-root {
        padding: 20px 8px !important;
    }



    .productComparisonItems {
        width: 12rem;
        height: 170px;
        margin: 5px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2px 4px;
    }

    .addProductComparison {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 12rem;
        height: 170px;
        margin: 5px 0px;
        padding: 2px 4px;
    }

    .text-submit {
        margin: 0;
    }
}