.checkout-container-model {
  margin-top: 1rem;
  width: 100%;
}

.checkout-container {
  display: flex;
}

.checkout-container-left {
  width: 62%;
}

.choose-delivery-method-container {
  background-color: #fff;
  font-size: 14px;
  overflow: hidden;
  border-radius: 5px;
}

.choose-delivery-method-list {
  display: flex;
  background-color: #fff;
  font-size: 14px;
  padding: 10px;
  border-bottom: 1px solid #f6f6f6;
}

.choose-delivery-item {
  padding: 0 5px;
  font-weight: 600;
  cursor: pointer;
}

.active-choose-delivery {
  position: relative;
  color: var(--primary-red);
}

.active-choose-delivery::after {
  content: "";
  position: absolute;
  bottom: -11px;
  left: 0;
  width: 100%;
  background-color: var(--primary-red);
  height: 2px;
}

.choose-delivery-item:hover {
  color: var(--primary-red);
}

.checkout-container-right {
  margin-left: 10px;
  width: 36%;
}

.note-container {
  margin-top: 10px;
  padding: 10px;
  background-color: #fff;
  font-size: 14px;
  overflow: hidden;
  border-radius: 5px;
}

.note-container p {
  font-weight: 500;
}

.note-input {
  padding: 5px 10px;
  outline: none;
  width: 100%;
  padding: 10px;
  border: 1px solid rgb(228, 229, 240);
  border-radius: 5px;
}

.continue-btn {
  cursor: pointer;
}

.choose-payment-method-container {
  margin-top: 10px;
  padding: 10px;
  background-color: #fff;
  font-size: 14px;
  overflow: hidden;
  border-radius: 5px;
}

.choose-payment-method-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.choose-payment-method-desc {
  font-weight: 500;
  color: rgb(130, 134, 158);
}

.choose-payment-method-list {
  display: flex;
  gap: 10px;
}

.choose-payment-method-item {
  position: relative;
  border: 1px solid;
  padding: 5px;
  font-weight: 600;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active-choose {
  overflow: hidden;
  border: 1px solid var(--primary-red);
}

.active-choose::after {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0px 30px 30px 0px;
  border-color: transparent var(--primary-red) transparent transparent;
}

.icon-check {
  display: flex;
  position: absolute;
  top: 0px;
  color: #fff;
  right: 1px;
  z-index: 1;
}

.payment-img {
  margin-top: 10px;
}

.Information-line-container {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
}

.Information-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.Information-line-title {
  font-size: 18px;
  font-weight: 600;
}

.Information-line-content-title-product-name {
  display: -webkit-box;
  /* min-height: 3rem; */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  line-height: 1.6rem;
  font-weight: 500;
  font-size: 13px;
}

.Information-line-image {
  width: 70px;
  border: 1px solid rgb(228, 229, 240);
  margin-right: 8px;
}

.Information-line-content {
  margin-top: 10px;
  padding: 5px 10px;
}

.Information-line-content-title {
  width: 80%;
  font-weight: 600;
  font-size: 12px;
  /* padding: 2px 15px; */
}

.Information-line-content__quantity,
.Information-line-content__price {
  color: rgb(132, 135, 136);
  font-weight: 500;
  font-size: 13px;
}

.fix-btn {
  color: #0d6efd;
  cursor: pointer;
}

.fix-btn:hover {
  opacity: 0.7;
}

.Information-line-content-title p {
  margin-bottom: 0;
}

.technical-support-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
}

.technical-support-container__applied {
  font-size: 18px;
  font-weight: 600;
}

.payment-container {
  background-color: #fff;
  margin-top: 16px;
}

.payment-item {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}

.checkout-vat {
  font-size: 14px;
  font-weight: 600;
  color: rgb(130, 134, 158);
}

.into-money {
  color: var(--primary-red);
  font-weight: 700;
}

.note-payment {
  margin-top: 10px;
  font-size: 14px;
}

@keyframes jump {
  0% {
    transform: scaleY(0.8);
  }

  100% {
    transform: translateY(-75px);
  }
}

@keyframes shrink {
  100% {
    transform: scaleX(0.5);
    opacity: 0.01;
  }
}

div.paypalProcessing {
  display: table;
  width: 100%;
  margin: 0 0 3em 0;
}

div.paypalProcessing div.animation {
  display: table-row;
  height: 50%;
  width: 100%;
}

div.paypalProcessing div.animation div.container {
  display: table-cell;
  height: 150px;
  width: 135px;
  text-align: center;
  vertical-align: bottom;
}

div.paypalProcessing div.animation div.container div.balls {
  height: 50px;
  width: 135px;
  margin: auto;
}

div.paypalProcessing div.animation div.container div.balls div.ball {
  width: 20px;
  position: relative;
  display: inline-block;
  margin: 5px;
  height: 20px;
  border-radius: 50%;
  z-index: 999;
  background-color: cornflowerblue;
}

div.paypalProcessing div.animation div.container div.balls div.ball.ball-one {
  animation: jump 0.5s ease 0s infinite alternate;
}

div.paypalProcessing div.animation div.container div.balls div.ball.ball-two {
  animation: jump 0.5s ease 0.15s infinite alternate;
}

div.paypalProcessing div.animation div.container div.balls div.ball.ball-three {
  animation: jump 0.5s ease 0.25s infinite alternate;
}

div.paypalProcessing div.animation div.container div.balls div.ball.ball-four {
  animation: jump 0.5s ease 0.35s infinite alternate;
}

div.paypalProcessing div.animation div.container div.balls div.shadow {
  position: relative;
  opacity: 0.1;
  bottom: 20px;
  width: 20px;
  height: 5px;
  border-radius: 50%;
  background-color: black;
  display: inline-block;
  margin: 5px;
}

div.paypalProcessing div.animation div.container div.balls div.shadow.shadow-one {
  animation: shrink 0.5s ease 0s infinite alternate;
}

div.paypalProcessing div.animation div.container div.balls div.shadow.shadow-two {
  animation: shrink 0.5s ease 0.15s infinite alternate;
}

div.paypalProcessing div.animation div.container div.balls div.shadow.shadow-three {
  animation: shrink 0.5s ease 0.25s infinite alternate;
}

div.paypalProcessing div.animation div.container div.balls div.shadow.shadow-four {
  animation: shrink 0.5s ease 0.35s infinite alternate;
}

div.paypalProcessing div.text {
  display: table-row;
  text-align: center;
}

div.paypalProcessing div.text h1 {
  display: table-cell;
  vertical-align: middle;
  color: #191919;
}

@media only screen and (max-width: 767px) {
  .checkout-container {
    display: block;
  }

  .checkout-container-left {
    width: 100%;
  }

  .choose-delivery-item {
    padding: 0 5px;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
  }

  .add-address {
    border: 1px solid #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 25rem;
    margin: 0 auto;
    height: 80px;
    cursor: pointer;
  }

  .choose-payment-method-item {
    position: relative;
    border: 1px solid;
    padding: 5px;
    font-weight: 600;
    border-radius: 5px;
    width: 26rem;
    text-align: center;
    cursor: pointer;
    height: 50px;
  }

  .active-choose {
    overflow: hidden;
    border: 1px solid var(--primary-red);
  }

  .active-choose::after {
    content: "";
    position: absolute;
    top: -1px;
    right: -1px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 30px 30px 0px;
    border-color: transparent var(--primary-red) transparent transparent;
  }

  .icon-check {
    display: flex;
    position: absolute;
    top: 0px;
    color: #fff;
    right: 1px;
    z-index: 1;
  }

  .payment-img {
    margin-top: 10px;
  }

  .Information-line-container {
    width: 100%;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
  }

  .Information-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }

  .Information-line-title {
    font-size: 18px;
    font-weight: 600;
  }

  .Information-line-content-title-product-name {
    display: -webkit-box;
    min-height: 3rem;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    line-height: 1.6rem;
  }

  .Information-line-image {
    width: 70px;
    height: 70px;
    border: 1px solid rgb(228, 229, 240);
  }

  .Information-line-content {
    margin-top: 10px;
    padding: 5px 10px;
  }

  .Information-line-content-title {
    width: 80%;
    font-weight: 600;
    font-size: 12px;
    padding: 2px 15px;
  }

  .fix-btn {
    color: #0d6efd;
    cursor: pointer;
  }

  .fix-btn:hover {
    opacity: 0.7;
  }

  .Information-line-content-title p {
    margin-bottom: 0;
  }

  .technical-support-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
  }

  .payment-container {
    background-color: #fff;
  }

  .payment-item {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
  }

  .checkout-vat {
    font-size: 14px;
    font-weight: 600;
    color: rgb(130, 134, 158);
  }

  .into-money {
    color: var(--primary-red);
    font-weight: 700;
  }

  .note-payment {
    margin-top: 10px;
    font-size: 14px;
  }

  @keyframes jump {
    0% {
      transform: scaleY(0.8);
    }

    100% {
      transform: translateY(-75px);
    }
  }

  @keyframes shrink {
    100% {
      transform: scaleX(0.5);
      opacity: 0.01;
    }
  }

  div.paypalProcessing {
    display: table;
    width: 100%;
    margin: 0 0 3em 0;
  }

  div.paypalProcessing div.animation {
    display: table-row;
    height: 50%;
    width: 100%;
  }

  div.paypalProcessing div.animation div.container {
    display: table-cell;
    height: 150px;
    width: 135px;
    text-align: center;
    vertical-align: bottom;
  }

  div.paypalProcessing div.animation div.container div.balls {
    height: 50px;
    width: 135px;
    margin: auto;
  }

  div.paypalProcessing div.animation div.container div.balls div.ball {
    width: 20px;
    position: relative;
    display: inline-block;
    margin: 5px;
    height: 20px;
    border-radius: 50%;
    z-index: 999;
    background-color: cornflowerblue;
  }

  div.paypalProcessing div.animation div.container div.balls div.ball.ball-one {
    animation: jump 0.5s ease 0s infinite alternate;
  }

  div.paypalProcessing div.animation div.container div.balls div.ball.ball-two {
    animation: jump 0.5s ease 0.15s infinite alternate;
  }

  div.paypalProcessing div.animation div.container div.balls div.ball.ball-three {
    animation: jump 0.5s ease 0.25s infinite alternate;
  }

  div.paypalProcessing div.animation div.container div.balls div.ball.ball-four {
    animation: jump 0.5s ease 0.35s infinite alternate;
  }

  div.paypalProcessing div.animation div.container div.balls div.shadow {
    position: relative;
    opacity: 0.1;
    bottom: 20px;
    width: 20px;
    height: 5px;
    border-radius: 50%;
    background-color: black;
    display: inline-block;
    margin: 5px;
  }

  div.paypalProcessing div.animation div.container div.balls div.shadow.shadow-one {
    animation: shrink 0.5s ease 0s infinite alternate;
  }

  div.paypalProcessing div.animation div.container div.balls div.shadow.shadow-two {
    animation: shrink 0.5s ease 0.15s infinite alternate;
  }

  div.paypalProcessing div.animation div.container div.balls div.shadow.shadow-three {
    animation: shrink 0.5s ease 0.25s infinite alternate;
  }

  div.paypalProcessing div.animation div.container div.balls div.shadow.shadow-four {
    animation: shrink 0.5s ease 0.35s infinite alternate;
  }

  div.paypalProcessing div.text {
    display: table-row;
    text-align: center;
  }

  div.paypalProcessing div.text h1 {
    display: table-cell;
    vertical-align: middle;
    color: #191919;
  }

  .checkout-container-right {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .checkout-container {
    display: block;
  }

  .checkout-container-left {
    width: 100%;
  }

  .choose-delivery-item {
    padding: 0 5px;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
  }

  .add-address {
    border: 1px solid #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 25rem;
    margin: 0 auto;
    height: 80px;
    cursor: pointer;
  }

  .choose-payment-method-item {
    position: relative;
    border: 1px solid;
    padding: 5px;
    font-weight: 600;
    border-radius: 5px;
    width: 26rem;
    text-align: center;
    cursor: pointer;
    height: 50px;
  }

  .payment-img {
    margin-top: 10px;
    width: 100%;
  }

  .checkout-container-right {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }

  .Information-line-image {
    width: 90px;
    height: 90px;
    border: 1px solid rgb(228, 229, 240);
  }

  .Information-line-content-title {
    width: 80%;
    font-weight: 600;
    font-size: 14px;
    padding: 2px 15px;
  }

  .gift-container-title {
    margin-top: 5px;
    display: flex;
    font-size: 14px;
    align-items: center;
  }
}