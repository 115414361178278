.nofication-container {
    background-color: #fff;
}

.nofication-item {
    display: flex;
    padding: 5px 15px;
    border-bottom: 1px solid #e0e0e0;
}

.nofication-right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nofication-icon-gift {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: var(--primary-red);
    height: 50px;
    background-color: rgb(243, 243, 247);
    font-size: 16px;
}

.coupon-item-title {
    text-transform: uppercase;
    color: var(--primary-red);
    font-weight: 700 !important;
}

.coupon-item-desc {
    margin-bottom: 0;
}

.nofication-item.active {
    background-color: #e3e0e0;
}