.comment-container {
    background-color: #fff;
    margin-top: 10px;
    border-radius: 5px;
    padding: 10px 15px;
}

.comment-title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    color: var(--primary-red)
}

.comment-input {
    outline: none;
    width: 100%;
    height: 80px;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 10px;
}

.preview-image {
    width: 35px;
    height: 35px;
}

.btn_upload {
    /* padding: 17px 30px 12px;  */
    color: var(--primary-blue);
    font-size: 14px; 
    text-align: center; 
    position: relative; 
    display: inline-block; 
    overflow: hidden; 
    z-index: 3; 
    white-space: nowrap;
    cursor: pointer;
}

.pointer {
    cursor: pointer !important;
}

.btn_upload input {
    position: absolute; 
    width: 100%; 
    left: 0; 
    top: 0; 
    width: 100%; 
    height: 105%; 
    cursor: pointer; 
    opacity: 0;
}

.css-dmmspl-MuiFormGroup-root {
    flex-direction: row !important;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 14px;
}

.info-input {
    width: 100%;
    border: 1px solid #d7d7d7;
    padding: 2px 5px 2px 5px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 7px;
    outline: none;
    height: 40px;
}

.submit-btn {
    width: 100%;
    height: 40px;
    border-radius: 7px;
    outline: none;
    border: none;
    background-color: var(--primary-color);
    color: var(--primary-red);
    font-weight: 700;
    font-size: 16px;
}

.comment-user-name{
    font-size: 15px;
    font-weight: 500;
}

.comment-user {
    font-size: 14px;
    padding: 5px 0;
    margin-bottom: 0;
}

.comment-option {
    font-size: 12px;
}

.time {
    color: #8f9bb3;
    font-weight: 600;
}

.comment-user-container {
    padding: 14px 10px 0 10px;
}

.reply-comment {
    position: relative;
    margin: 5px 7px;
    padding: 10px 15px;
    background-color: #f7f7f7;
}

.reply-comment::before {
    content: "";
    border: 16px solid;
    border-color: transparent transparent #f7f7f7 transparent;
    position: absolute;
    left: 0px;
    /* right: 25px; */
    top: -32px;
}

.comment-img {
    width: 50px;
    height: 50px;
}

