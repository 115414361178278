.container_detail_hire {
  max-width: 1366px;
  margin: 0px auto;
}
.box_job_related,
.box_info_company,
.box_simple_parameters {
  background: white;
  padding: 25px;
  border-radius: 8px;
}
.box_name_job span {
  font-size: 22px;
  font-weight: bold;
  color: #263a4d;
  line-height: normal;
}

.standard_text {
  display: block;
  font-size: 18px;
  color: #263a4d;
  font-weight: 400;
}
.value_standard {
  color: #212f3f;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.175px;
}
.icon_standard {
  font-size: 22px;
}
.img_standard {
  display: inline-block;
  padding: 10px;
  border: 1px solid;
  border-radius: 50%;
  background: linear-gradient(11deg, #3c44a7, #27c1ab);
  color: white;
}
.box_text_standard {
  display: inline-block;
}

.item_standard {
  display: flex;
  align-items: center;
  gap: 10px;
}
.deadline_apply {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 1px 35px;
  border: 1px solid #f2f4f5;
  border-radius: 5px;
  background-color: #f2f4f5;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.deadline_apply span {
  font-size: 14px;
}

.box_btn_apply {
  cursor: pointer;
  text-align: center;
  padding: 5px 10px;
  border: 1px solid var(--primary-blue);
  border-radius: 5px;
  background-color: var(--primary-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.box_btn_messenger,
.box_btn_apply span {
  font-size: 17px;
  line-height: 24px;
  font-weight: 500;
  margin-left: 5px;
  color: var(--primary-blue);
}

.icon_mess_apply {
  font-size: 18px;
  color: var(--primary-blue);
}

.box_btn_messenger {
  cursor: pointer;
  border: 1px solid var(--primary-blue);
  text-align: center;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.box_btn_messenger:hover {
  border: 1px solid #90a1b1;
}
.box_btn_apply:hover {
  background-color: #1971c5;
}
.box_btn_messenger,
.box_btn_apply {
  transition: all 0.3s ease;
}
.box_detail_parameters {
  padding: 25px;
  background: white;
  border-radius: 8px;
}

.text_title_content_detail span {
  font-size: 22px;
  font-weight: bold;
  color: #263a4d;
  border-left: 10px solid var(--primary-blue);
  padding: 5px 0px 5px 5px;
}

.text_description_content_detail span {
  line-height: 10px;
  color: #212f3f;
  font-size: 17px;
  font-weight: 500;
}
.text_content_content_detail {
  font-size: 14px;
}

.image.is-5by4 img {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.image {
  display: block;
  position: relative;
}
.image img {
  display: block;
  height: auto;
  width: 100%;
}

.image.is-5by4 {
  padding-top: 90%;
}

.box_text span {
  font-size: 20px;
  font-weight: bold;
  color: #263a4d;
  letter-spacing: 1px;
}
.box_icon_nature {
  display: inline-block;
  padding: 10px;
  border: 1px solid;
  border-radius: 50%;
  background: linear-gradient(11deg, #3c44a7, #27c1ab);
  color: white;
  font-size: 22px;
  line-height: 1px;
  margin-right: 10px;
}

.text_rank {
  font-size: 16px;
  font-weight: 400;
}
.text_value {
  font-size: 16px;
  font-weight: 500;
}

.box_name_job_related span {
  font-weight: 500;
  white-space: nowrap;
  letter-spacing: 0.175px;
}

.box_name_job_related {
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 18px;
}

.box_company_job_related {
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
}

.box_company_job_related span {
  color: #6f7882;
  letter-spacing: 0.175px;
  font-weight: 400;
}

.col_img_job_related {
  border: 1px solid #e9eaec;
  border-radius: 5px;
}

.box_item_job_related {
  border: 1px solid #e9eaec;
  border-radius: 5px;
  margin: 5px 0px;
}

.box_text_location_time span {
  font-size: 18px;
  font-weight: 600;
  color: #212f3f;
}

.box_text_location_time p {
  margin-top: 4px;
  font-size: 15px;
  line-height: 12px;
  font-weight: 400;
}
.box_card_content_detail_hire li {
  margin-top: 4px;
  font-size: 15px;
  line-height: normal;
  font-weight: 400;
}

.box_content_right {
  height: fit-content;
  position: sticky;
  top: 1px;
}
.apply_now {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: white;
}

.box_apply_now {
  cursor: pointer;
  padding: 10px;
  background: var(--primary-blue);
  display: inline-block;
  border-radius: 4px;
}
.deadline_apply_text {
  font-size: 16px;
  font-weight: 400;
}

.box_img_banner_hire {
  position: relative;
}

.box_text_amination {
  position: absolute;
  top: 15%;
  right: 50%;
  transform: translateX(50%);
  background: rgb(22 22 22 / 40%);
  padding: 10px 10px;
  border-radius: 5px;
}
.box_text_amination span {
  font-size: 29px !important;
  font-weight: 600;
  color: white;
  font-style: normal;
}
.name_job_modal {
  font-size: 18px;
  color: var(--primary-blue);
}

.letters_introduce {
  min-height: 90px !important;
}

.box_title_modal_aplly {
  display: flex;
  align-items: center;
  color: var(--primary-blue);
}

.box_title_modal_aplly svg {
  font-size: 20px;
}

.box_title_modal_aplly span {
  font-size: 14px;
  line-height: normal;
}

.input_file_cv {
  display: none;
}

.box_btn_click_file {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border-style: dashed;
}

.text_placeholder {
  font-size: 14px;
  color: #555;
}
.name_file {
  margin-left: 6px;
  font-size: 15px;
  font-weight: 500;
}
.box_btn_click_file:hover {
  border: 1px solid #86b7fe;
  border-style: dashed;
}

.form-control:hover {
  border: 1px solid #86b7fe;
}
.text_use_upload_2 {
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #1d851d;
}
.text_use_upload {
  font-size: 15px;
  font-weight: 500;
}

.icon_upload {
  font-size: 40px;
  color: #d9d8d8;
  margin-right: 6px;
}
.text_box_upload {
  text-align: center;
  font-size: 13px;
  color: #7f878f;
}

.btn_click_choose {
  cursor: pointer;
  font-size: 18px;
  border: 1px solid;
  padding: 4px 10px;
  border-radius: 5px;
  background: var(--primary-blue);
  color: white;
  border: 1px solid #ccc;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  transition: all 0.3s;
}
.btn_click_choose:hover {
  background: #054683;
}

.name_file_selected svg {
  font-size: 24px;
  color: var(--primary-blue);
}
/* .name_file_selected span{
    color: var(--primary-blue);
} */
.name_file_selected {
  display: inline-block;
  padding-right: 5px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 700px;
  }
}

.btn_submit_apply_job {
  float: right;
  width: 70%;
  padding: 4px;
  font-size: 18px;
  font-weight: 500;
}

.btn_cancel_apply_job {
  float: left;
  width: 29%;
  padding: 4px;
  font-size: 18px;
  font-weight: 500;
  border: 1px solid var(--primary-blue);
  background: white;
  color: var(--primary-blue);
}
.btn_cancel_apply_job:hover {
  border: 1px solid #90a1b1;
  background: white;
  color: var(--primary-blue);
}
.box_candidate_information svg,
.deadline_apply svg {
  margin-right: 6px;
  font-size: 24px;
  color: var(--primary-blue);
}

.box_candidate_information {
  cursor: pointer;
  width: fit-content;
  padding: 1px 35px;
  border: 1px solid #f2f4f5;
  border-radius: 5px;
  background-color: #f2f4f5;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.box_candidate_information span {
  font-size: 14px;
  color: var(--primary-blue);
  font-weight: 500;
}

.container_text_deadline_candidate {
  display: flex;
  gap: 10px;
}
.box_icon_nodata span {
  font-size: 22px;
  font-weight: 500;
  color: var(--primary-blue);
}
.box_icon_nodata svg {
  font-size: 50px;
  color: var(--primary-blue);
}

.box_icon_nodata {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}
.box_infor_job_ckeditor p {
  font-size: 16px;
}
.text_err {
  font-size: 14px;
  margin-left: 0px;

  color: #dc3545;
  font-weight: 500;
  min-height: 22px;
}
.color_success {
  color: #1d851d !important;
}
.color_err {
  color: red;
}

@media (max-width: 600px) {
  .box_candidate_information {
    padding: 5px;
  }
  .deadline_apply {
    padding: 5px;
  }

  .box_candidate_information span {
    font-size: 12px;
  }
  .box_detail_parameters,
  .box_job_related,
  .box_info_company,
  .box_simple_parameters {
    padding: 10px;
  }

  .name_job_company {
    font-size: 18px;
  }
  .text_time_job {
    font-size: 11px;
  }
  .name_company_hire {
    font-size: 14px;
  }
}
