.product-all__container {
  margin-top: 20px;
}

.product-all__box {
  align-items: flex-start;
}

/* .product-all__left-container {
  height: auto;
  max-height: 100vh;
  overflow-y: auto;
} */

.product-all__left-container {
  padding: 10px;
  background-color: white;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.product-all__right-container {
  padding: 16px;
  background-color: white;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left: 1px solid #ebebeb;
}

.left-container__title {
  padding: 4px 6px;
  background-color: #ed1c24;
  color: white;
  font-size: 16px;
  border-radius: 4px;
}

.left-container__category-list {
  margin-bottom: 0;
  height: auto;
  max-height: 100vh;
}

.left-container__category-list li {
  /* outline: 1px solid lime; */
  padding: 6px;
  margin-bottom: 8px;
  font-size: 14px;
  border-bottom: 1px dashed #ccc;
  color: #555;
  transition: all 0.5s ease-in;
}

.left-container__category-list li:hover {
  background-color: #ebebeb;
  cursor: pointer;
  color: var(--primary-blue);
  transform: translateX(4px);
}

.left-container__category-list li:first-child {
  margin-top: 8px;
}

.left-container__category-list li:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.product-all__right-title {
  color: var(--primary-blue);
  border-bottom: 1px solid;
  padding-bottom: 8px;
}

.right-container__products-list {
  display: grid;
  width: 100%;
  grid-template-columns: 24% 24% 24% 24%;
  column-gap: 14px;
  row-gap: 15px;
}

.product-all__right-radio {
  font-size: 14px;
}

/* Mobile */

@media (max-width: 768px) {
  .product-all__container {
    margin-top: 0;
  }
  .product-all__right-container {
    padding: 8px;
  }

  .right-container__products-list {
    grid-template-columns: 48% 48%;
  }

  .product-all__left-container {
    display: none;
  }
  .product-all__right-filter {
    display: none;
  }
  .product-all__right-container .accordion-button {
    padding: 12px;
  }

  .product-all__right-container .category-accordion {
    margin-bottom: 8px;
  }

  .product-all__right-container .accordion-body {
    padding-top: 0;
  }
}

/* Tablet */

@media (min-width: 768px) and (max-width: 1024px) {
  .product-all__container {
    margin-top: 20px;
  }
  .product-all__right-container {
    padding: 12px;
  }

  .right-container__products-list {
    grid-template-columns: 32% 32% 32%;
  }
}

/* Desktop */

@media (min-width: 1024px) {
  .product-all__container {
    margin-top: 20px;
  }

  .product-all__right-container .category-accordion {
    display: none;
  }
}
