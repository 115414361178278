.sub-menu-container {
  background-color: #fff;
  border-left: 1px solid #f6f6f6;
  display: flex;
  border-radius: 8px;
  width: calc(100vw - 35vw);
  height: 100%;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  flex-wrap: wrap;
}

.sub-menu-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--primary-red) !important;
}

.sub-menu-item {
  font-size: 14px;
  margin: 5px 0;
}

.sub-menu-item-title {
  color: #000 !important;
}

.sub-menu-item:hover .sub-menu-item-title {
  color: var(--primary-red) !important;
}
