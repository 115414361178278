.promotion-ticket-container {
  margin-bottom: 15px;
  /* max-width: 38rem; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
}

.promotion-ticket-ribbon {
  border-radius: 0.125rem 0.125rem 0.125rem 0;
  color: #fff;
  display: flex;
  font-size: 18px;
  line-height: 0.8rem;
  padding: 10px;
  position: absolute;
  top: 5px;
  left: -6px;
  background-color: var(--primary-red);
}

.promotion-ticket-ribbon::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -8px;
  border-top: 8px solid darkred;
  border-left: 8px solid transparent;
}

/* .promotion-ticket-middle {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    font-size: 12px;
    justify-content: space-evenly;
    overflow: hidden;
    padding-left: 0.75rem;
    position: relative;
} */

/* .promotion-ticket-middle-date {
    -webkit-box-orient: vertical;
    color: rgba(0,0,0,.54);
    -webkit-line-clamp: 2;
    display: flex;
    display: -webkit-box;
    line-height: unset;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
} */

/* .promotion-ticket-right {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.75rem;
    position: relative;
} */

/* .promotion-ticket-right-use-now {
    color: var(--primary-red);
    font-size: 13px;
    margin-right: 1.4rem;
    position: relative;
    cursor: pointer;
    text-decoration: none;
} */

/* .promotion-ticket-right-use-now-icon {
    position: absolute;
    right: -17px;
    font-size: 20px;
} */

/* .promotion-ticket-right-condition-btn {
    font-size: 12px;
    color: var(--primary-blue);
    cursor: pointer;
}  */

.promotion-ticket-container-img {
  width: 100%;
  height: 220px;
  margin-bottom: 5px;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  overflow: hidden;
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
}

.promotion-ticket-container-content {
  padding: 10px;
}

.time-deadline {
  font-size: 14px;
  color: var(--text-gray);
}

/* .promotion-ticket-container-img:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1); 
    -moz-transform: scale(1.1); 
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
} */

.promotion-ticket-container-title {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.promotion-ticket-container-title:hover {
  color: var(--primary-red);
}
