.product-show {
  margin-top: 1rem;
}

.category-desc {
  margin: 20px 0;
  font-size: 16px;
}

.category-desc h1 {
  font-size: 32px;
  text-align: center;
}

.category-desc h2 {
  font-size: 28px;
}

.category-desc p {
  font-size: 16px;
  font-weight: 400;
}

.category-desc ul>li {
  font-size: 16px;
}

.category-desc img {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.css-fvipm8 {
  font-size: 18px !important;
  font-weight: 700 !important;
}

.css-lald77x.Mui-focused {
  font-size: 16px !important;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.pagination li {
  display: inline-block;
  margin-right: 10px;
}

.pagination li a {
  color: #333;
  /* padding: 10px 15px; */
  font-size: 14px;
  /* border: 1px solid #ccc; */
  text-decoration: none;
  display: inline-flex;
  width: 34px;
  height: 34px;
  align-items: center;
  justify-content: center;
}

.test {
  display: grid;
  width: 100%;
  grid-template-columns: 19% 19% 19% 19% 19%;
  column-gap: 14px;
  /* grid-template-rows: auto auto; */
  row-gap: 15px;
}

.filter-title-top {
  margin-top: 10px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
}

.pagination li a:hover {
  opacity: 0.7;
}

.pagination li.active a {
  background-color: var(--primary-blue);
  border-radius: 50%;
  color: #fff;
}

.container-product-right {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}

.container-product-right-title {
  font-size: 24px;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  font-weight: 700;
}

.container-product-right-title-quantity-product {
  margin-bottom: 0;
  margin-left: 5px;
  font-weight: 600;
  color: #888;
}

.container-product-right-filter {
  display: flex;
  margin: 15px 0;
  align-items: center;
}

.container-product-right-filter-title {
  font-size: 14px;
  margin-right: 10px;
  font-weight: 600;
}

.container-product-right-filter-list {
  display: flex;
  width: 90%;
  align-items: center;
}

.container-product-right-filter-list-item {
  border: 1px solid #e0e0e0;
  padding: 6px;
  margin-right: 5px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
}

.container-product-right-filter-list-item:hover {
  color: var(--primary-red);
}

.container-product-right-filter-mobile {
  display: none;
}

.no-product {
  font-size: 20px;
  font-weight: 700;
  color: #888;
  text-align: center;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 12px;
  color: #888;
  font-weight: 600;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 14px !important;
}

.box-filter__cate-child {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  list-style-type: none;
  padding-left: 0;
  font-size: 14px;
}

.box-filter__cate-child>li {
  padding: 5px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
}

.box-filter__cate-child>li a {
  color: black;
}

.box-filter__cate-child>li:hover {
  background-color: #ddd;
  transition: all ease 0.2s;
}

/* mobile */

@media only screen and (max-width: 767px) {


  .container-product-right-filter {
    display: none;
  }

  .test {
    display: grid;
    width: 100%;
    grid-template-columns: 48% 48%;
    column-gap: 15px;
    /* grid-template-rows: auto auto; */
    row-gap: 15px;
  }

  .side-bar {
    display: none;
  }

  .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    width: 22rem;
  }

  .container-product-right-filter-mobile {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filter-title {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: #000;
  }

  .box-filter__cate-child>li {
    padding: 5px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 20px;
    border: 1px solid #e0e0e0;
    box-shadow: none;
  }
}

/* tablet */
@media only screen and (min-width: 767px) and (max-width: 1023px) {


  .container-product-right-filter {
    display: none;
  }

  .test {
    display: grid;
    width: 100%;
    grid-template-columns: 24% 24% 24% 24%;
    column-gap: 12px;
    /* grid-template-rows: auto auto; */
    row-gap: 15px;
  }

  .container-product-right-filter-mobile-sideBar {
    display: none;
  }

  .container-product-right-filter-mobile {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filter-title {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: #000;
  }
}