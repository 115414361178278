:root {
  --white-color: #fff;
  --black-color: #000;
  --text-color: #333;

  --header-height: 100%;
  --navbar-height: 100%;
  --search-navbar: calc(var(--header-height) - var(--navbar-height));
  --header-sort-bar-height: 100%;
}

.header__navbar-item {
  position: relative;
  margin: 0 8px;
}

.menu {
  padding-top: 60%;
  padding-right: 10px;
}

.header__navbar-item,
.header__navbar-item-link {
  font-size: 1.5rem;
  color: #0032c1;
  align-items: center;
  display: flex;
  font-weight: 600;
  cursor: pointer;
}

.login {
  text-align: center;
}

.header__navbar-item-link:hover {
  color: #0032c1;
  cursor: pointer;
}

.build-pc-icon {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

/*CSS cua phần thông báo*/
.thongbao {
  width: 400px;
  height: 350px;
  position: absolute;
  display: none;
  top: 20px;
  right: 0;
  margin-top: 15px;
}

.thongbao::before {
  content: "";
  border: 20px solid;
  border-color: transparent transparent white transparent;
  position: absolute;
  right: 25px;
  top: -35px;
}

.header__navbar-item--thongbao:hover .thongbao {
  display: block;
}

.section__khungthongbao {
  width: 400px;
  height: 310px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 3px;
}

.hinhanh {
  background-color: white;
  position: relative;
  width: 100%;
  color: black;
}

.thongtin {
  display: flex;
  height: 40px;
  width: 100%;
}

.thongtin-left,
.thongtin-right {
  width: 200px;
  height: 40px;
  border: none;
  cursor: pointer;
  background-color: #f5f5f5;
}

.thongtin-left:hover,
.thongtin-right:hover {
  color: #ee4d2d;
  background-color: #e8e8e8;
}

.header_main {
  background: #ffd201;
  padding: 10px 5px;
}

.note {
  margin: 20px 0 0 0;
}

.header-container {
  position: sticky;
  width: 100%;
  height: fit-content;
  top: 0;
  margin: 0;
  z-index: 950;
}

.drum {
  display: none;
}

.top_header {
  height: 2.5rem;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.top-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-header-container-item {
  font-size: 12px;
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-right: 15px;
  cursor: pointer;
  text-decoration: none;
}

.top-header-container-item:hover {
  color: var(--primary-red);
}

.top-header-container-item-icon {
  font-size: 20px;
  margin-right: 10px;
}

.header-main-item-link {
  text-decoration: none !important;
  display: flex;
  margin-right: 5px;
  align-items: center;
  color: #fff;
}

.header-main-item-container {
  display: flex;
}

/*logo_search_cart*/

.logo__nk {
  position: relative;
  padding-right: 4rem;
}

.nk__icon {
  fill: #0032c1;
  display: block;
}

.nk_image {
  width: 270px;
}

.search__navbar {
  height: var(--search-navbar);
  padding: 16px 0 10px 0;
}

.search {
  width: 100%;
}

.header__search-checkbox:checked~.search {
  display: flex;
}

.search__item {
  display: flex;
  align-items: center;
  position: relative;
  width: 24rem;
  height: 40px;
  border-radius: 20px;
  background-color: white;
}

.search__item-result {
  position: absolute;
  top: 100%;
  z-index: 1;
  border-radius: 10px;
  background-color: #fff;
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
  border: 1px solid #ddd;
}

.search__item-result-item {
  display: flex;
  padding: 8px 0px;
  border-bottom: 1px solid #e5e5e5;
}

.search__item-result-img-box {
  justify-content: center;
  min-width: 75px;
  height: 60px;
  display: flex;
  align-items: center;
}

.search__item-result-img {
  width: 100% !important;
  background-size: cover;
  height: 100%;
}

.search__item-result-item-right {
  /* width: 190px; */
  margin-left: 5px;
}

.search__item-result-item-right-title {
  font-size: 12px;
  color: #333;
  font-weight: 500;
  display: -webkit-box;
  min-height: 3rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  line-height: 1.6rem;
}

.search__item-result-item-right-price {
  font-size: 12px;
  font-weight: 700;
  color: var(--primary-red);
}

.search__bar {
  flex: 1;
}

.search__bar-input {
  width: 100%;
  margin: 0;
  box-sizing: border-box;
}

.search__input {
  margin-left: 8px;
  padding: 0 0 0 10px;
  border: none;
  outline: none;
  font-size: 1rem;
  width: 100%;
}

.search__button {
  width: 38px;
  height: 38px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #fb5533;
  cursor: pointer;
  border: none;
  border-radius: 50%;
}

.search__button:hover {
  opacity: 0.7;
}

.search-icon {
  color: #fff;
}

.nk-svg-icon {
  margin: 0 7px 0 7px;
  fill: white;
}

.list__link {
  width: 100%;
  flex: 1;
  display: flex;
}

.list__item {
  width: 100%;
}

.list {
  font-size: 14px;
  font-weight: 400;
  color: #0032c1;
  white-space: nowrap;
  margin-left: 13px;
}

.list:first-child {
  margin-left: 0px;
}

.shopping {
  display: flex;
  flex: 1;
  margin: 0 10px;
  align-items: center;
  justify-content: center;
}

.shopping_mobile {
  padding-top: 200px;
}

.header__cart {
  width: 55px;
  padding: 10px 0;
  cursor: pointer;
  margin-top: -30px;
}

.header__cart_mobile {
  width: 55px;
  padding: 10px 0;
  cursor: pointer;
}

.header__cart-notice {
  position: absolute;
  background-color: white;
  padding: 1px 8px;
  border-radius: 12px;
  border: 1px solid #f6432e;
  font-size: 1rem;
  top: -12;
  right: 5;
  color: #ee4d2d;
}

.fas {
  font-size: 2.5rem;
  color: #0032c1;
}

.btn-link {
  color: #0032c1 !important;
  font-size: 15px !important;
  margin-top: -3px !important;
}

.btn-forgot {
  color: red !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 800px !important;
}

.form-login {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  text-align: center;
}

.name-header {
  margin-left: 10px;
}

.avatar {
  width: 25px;
  border-radius: 50%;
}

.disable-block {
  display: block;
}

.drum.disable-block {
  display: none;
}

.header-main-container {
  justify-content: space-between;
  display: flex;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  color: #fff;
  align-items: center;
  gap: 20px;
}

.header-main-item {
  color: var(--primary-blue);
  display: flex;
  margin-right: 10px;
  align-items: center;
  text-decoration: none;
}

.header-main-item .css-1c32n2y-MuiBadge-root .css-to4x1n-MuiBadge-badge {
  right: 25px !important;
}

.header-main-item-sup {
  color: var(--primary-blue);
  display: flex;
  margin-right: 10px;
  align-items: center;
  position: relative;
}

.header-main-sup-item-dropdown {
  display: none;
}

.header-main-item-sup:hover .header-main-sup-item-dropdown {
  display: block;
  position: absolute;
  color: #000;
  top: 55px;
  right: -25rem;
  min-width: 700px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  z-index: 1;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
}

.header-main-sup-item-dropdown::before {
  content: "";
  position: absolute;
  top: -20px;
  width: 100%;
  height: 25px;
  /* background-color: red; */
}

.header-main-sup-item-dropdown::after {
  content: "";
  position: absolute;
  right: 400px;
  top: -40px;
  border-color: transparent transparent var(--primary-blue) transparent;
  border-style: solid;
  border-width: 25px 25px 25px 25px;
  height: 0px;
  width: 0px;
}

.header-main-item:hover {
  color: var(--primary-red);
  opacity: 0.7;
}

.header-main-item-icon {
  font-size: 25px;
  margin-right: 5px;
}

.header-button:hover {
  color: red;
}

.header-main-consultant-dropdown {
  position: absolute;
  display: none;
  top: 330px;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 3;
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

/* .header-main-consultant-dropdown::before {
    content: '';
    position: absolute;
    top: -20px;
    width: 100%;
    height: 25px;
   
} 

.header-main-consultant-dropdown::after {
    content: '';
    position: absolute;
    left: 16px;
    top: -24px;
    border-color: transparent transparent var(--primary-blue) transparent;
    border-style: solid;
    border-width: 12px 12px 12px 12px;
    height: 0px;
    width: 0px;
} */

.header-main-btn {
  position: relative;
  color: #fff;
  border-radius: 5px;
  background-color: rgb(255 177 0);
  padding: 5px 6px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
}

.header-main-category-list {
  position: relative;
}

.header-main-category-list-menu {
  display: none;
}

.header-main-category-list:hover .header-main-category-list-menu {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.header-main-btn:hover {
  color: #fff;
  /* opacity: 0.7; */
}

.menu-icon {
  display: none;
}

.header-list-icon {
  display: none;
}

.user-name-btn {
  position: relative;
}

.user-name-dropdown {
  display: none;
}

.user-name-btn:hover .user-name-dropdown {
  display: block;
  position: absolute;
  color: #000;
  top: 45px;
  right: -1px;
  width: 200px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
}

.user-name-dropdown::before {
  content: "";
  position: absolute;
  top: -20px;
  width: 100%;

  height: 25px;
  /* background-color: red; */
}

.user-name-dropdown::after {
  content: "";
  position: absolute;
  right: 31px;
  top: -36px;
  border-color: transparent transparent #fff transparent;
  border-style: solid;
  border-width: 20px 20px 20px 20px;
  height: 0px;
  width: 0px;
}

.user-name-dropdown-item {
  padding: 4px 10px;
  color: #000;
  /* border-bottom: 1px solid #e3e3e3; */
}

.user-name-dropdown-item:hover {
  color: var(--primary-red);
  background-color: #e3e3e3;
}

.css-106c1u2-MuiBadge-badge {
  font-size: 10px !important;
  top: -2px !important;
  right: 3px !important;
}

.history-search-title {
  font-weight: 600;
  color: #888;
  font-size: 18px;
}

.history-search-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.history-search-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}

.history-search-key {
  font-weight: bold;
  display: -webkit-box;
  min-height: 1rem;
  width: 90%;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  line-height: 1.6rem;
}

.history-search-key:hover {
  color: var(--primary-red);
}

.history-search-icon {
  color: #000;
}

.history-search-icon:hover {
  opacity: 0.5;
}

.search__button-mobile {
  display: none;
}

.search__button-desktop {
  display: block;
}

.no-wrap-text {
  white-space: nowrap;
}

.not-found-product {
  color: #888;
  font-size: 14px;
  text-align: center;
}

.custom-loader {
  width: 50px;
  height: 24px;
  background: radial-gradient(circle closest-side, #0459aa 90%, #0000) 0% 50%,
    radial-gradient(circle closest-side, #0459aa 90%, #0000) 50% 50%,
    radial-gradient(circle closest-side, #0459aa 90%, #0000) 100% 50%;
  background-size: calc(100% / 3) 12px;
  background-repeat: no-repeat;
  animation: d3 1s infinite linear;
}

@keyframes d3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }

  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }

  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }

  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

.header-main-item.header-main-nofication {
  position: relative;
}

.header-main-nofication-dropdown {
  display: none;
}

.header-main-item.header-main-nofication:hover {
  color: var(--search-navbar);
  opacity: 1;
}

.header-main-item.header-main-nofication:hover .header-main-nofication-dropdown {
  display: block;
  position: absolute;
  color: #000;
  top: 55px;
  right: 0;
  min-width: 350px;
  border-radius: 5px;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 500;
  z-index: 1;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
}

.header-main-nofication-dropdown::before {
  content: "";
  position: absolute;
  top: -20px;
  width: 100%;
  height: 25px;
  /* background-color: red; */
}

.header-main-nofication-dropdown::after {
  content: "";
  position: absolute;
  right: 15px;
  top: -40px;
  border-color: transparent transparent #fff transparent;
  border-style: solid;
  border-width: 25px 25px 25px 25px;
  height: 0px;
  width: 0px;
}

/* mobile */
@media only screen and (max-width: 481px) {
  .shopping_mobile {
    display: none;
  }

  .header-main-container {
    display: block;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    color: #fff;
  }

  .search__item-result-item {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding: 6px 0px;
  }

  .search__item-result-img-box {
    justify-content: center;
    width: 20%;
    height: 60px;
    display: flex;
    align-items: center;
  }

  .search__item-result-item-right {
    /* width: 190px; */
    width: 60%;
    margin-left: 5px;
  }

  .header-main-item-container {
    display: none !important;
  }

  .search__item {
    margin-top: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 2px;
    border-radius: 20px;
    background-color: white;
  }

  .menu-icon {
    display: block;
    color: #fff;
  }

  .header-sidebar-swipe .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    width: 25rem;
  }

  .header-list-icon {
    display: block;
  }

  .header-main-category-list {
    display: none;
  }

  .search__item-result {
    position: absolute;
    top: 100%;
    z-index: 1;
    border-radius: 10px;
    background-color: #fff;
    max-height: 35rem;
    overflow-y: scroll;
    width: 100%;
    border: 1px solid #ddd;
  }

  .css-106c1u2-MuiBadge-badge {
    font-size: 12px !important;
    top: 8px !important;
    right: 0px !important;
  }

  .search__button-desktop {
    display: none;
  }

  .search__button-mobile {
    display: block;
  }
}

/* @media only screen and (max-width: 739px) {
  .header-main-item-container {
    display: none !important;
  }
} */

@media (min-width: 767px) and (max-width: 1023px) {
  .header-main-item-sup:hover .header-main-sup-item-dropdown {
    right: -32rem;
    min-width: 100%;
  }
}

@media only screen and (min-width: 481px) and (max-width: 641px) {
  .header-main-item-sup:hover .header-main-sup-item-dropdown {
    display: block;
    position: absolute;
    color: #000;
    /* overflow: hidden; */
    top: 55px;
    right: -17rem;
    min-width: 90vw;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    z-index: 1;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #fff;
  }

  .header-main-sup-item-dropdown::after {
    content: "";
    position: absolute;
    right: 180px;
    top: -40px;
    border-color: transparent transparent var(--primary-blue) transparent;
    border-style: solid;
    border-width: 25px 25px 25px 25px;
    height: 0px;
    width: 0px;
  }

  .search__button-desktop {
    display: none;
  }

  .search__button-mobile {
    display: block;
  }
}

@media (min-width: 641px) and (max-width: 960px) {
  .header-main-container {
    flex-wrap: wrap;
    gap: 10px;
  }
}

.function_bar {
  width: 100%;
  padding: 10px;
  background: white;
}