.promotion-container {
    margin-top: 1rem;
    padding: 15px 0;
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
}

.promotion-mobile {
    display: none;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-size: 12px;
    font-weight: 700;
    color: var(--primary-red) !important;
}

.css-10d9dml-MuiTabs-indicator {
    background-color: var(--primary-red) !important;
}

.MuiBox-root.css-1gsv261 {
    min-width: 18rem;
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.12);
}

.MuiBox-root.css-1n3h4wo {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    background-color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-width: 50px !important;
    height: unset;
}

.promotion-input-title {
    font-weight: 600;
}

.promotion-input {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    margin-bottom: 10px;
}

.promotion-input-code {
    outline: none;
    width: 30rem;
    margin: 0 10px;
    display: flex;
    align-items: center;
    background-color: #fff;
    position: relative;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, .14);
    box-sizing: border-box;
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, .02);
    color: #222;
    height: 40px;
    padding: 10px;
}

.save-btn {
    width: 8rem;
    height: 4rem;
    text-align: center;
    border-radius: 0.125rem;
    outline: none;
    color: #fff;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .09);
    background: var(--primary-red);
    justify-content: center;
    cursor: pointer;
    align-items: center;
    display: flex;
}

.save-btn.disabled {
    background: rgba(0, 0, 0, .1);
    cursor: default;
}

.no-promotion-title {
    font-size: 16px;
    font-weight: 700;
    color: var(--primary-red);
}

.promotion-container-content {
    margin: 15px;
    display: grid;
    column-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    padding: 0px 10px;

}

.promotion-container-title {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--primary-blue);
}

@media only screen and (max-width: 767px) {
    .promotion-mobile {
        display: block;
        min-height: 30rem;
    }

    .promotion-desktop {
        display: none;
    }

    .MuiBox-root.css-19kzrtu {
        padding: 15px;
    }

    .css-1aquho2-MuiTabs-indicator {
        background-color: var(--primary-red) !important;
    }

    .promotion-container-content {

        display: grid;
        grid-template-columns: repeat(1, 1fr)
    }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
    .promotion-mobile {
        display: none;
    }

    .promotion-container-content {
        margin: 15px;
        display: grid;
        grid-template-columns: repeat(2, 1fr)
    }
}