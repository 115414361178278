.quote-container {
  margin-top: 32px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
}

.quote-form {
  max-width: 600px;
  margin: 0 auto;
}

.quote-form h2 {
  position: relative;
  text-align: center;
  font-size: 28px;
  color: var(--primary-blue);
  font-weight: 600;
  letter-spacing: -1px;
  margin-top: 30px;
}

.quote-form h2::after {
  content: "";
  display: block;
  width: 20%;
  height: 3px;
  background: var(--primary-color);
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
}

.quote-form__content {
  font-size: 14px;
  text-align: center;
  margin: 16px 0;
  color: var(--text-gray);
}

.quote-form__box {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.quote-form__input {
  flex: 1;
}

.quote-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 14px;
  color: var(--text-gray);
  width: 24%;
}

.quote-form__box label span {
  color: var(--primary-red);
  font-weight: 300;
}

.error_msg {
  font-size: 14px;
  color: var(--primary-red);
}

.quote-form input,
.quote-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 0px;
  font-size: 14px;
  outline: none;
}

.quote-form input:focus,
.quote-form textarea:focus {
  border-color: var(--primary-blue);
}

.form-buttons {
  display: flex;
  justify-content: center;
}

.form-buttons button {
  padding: 4px 32px;
  color: #003399;
  border: 1px solid #003399;
  cursor: pointer;
  border-radius: 5px;
}

.form-buttons button:hover {
  background-color: #003399;
  color: white;
  transition: all ease-in 0.2s;
}

.quote-form__box:nth-of-type(7) input {
  border: none;
  padding: 0;
}

.custom-file-button {
  background-color: #003399;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.custom-file-button:hover {
  background-color: #002277;
}

.quote-form__submit {
  font-size: 16px;
}

.quote-form__box .error {
  font-size: 14px;
  color: var(--primary-red);
}
