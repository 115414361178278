.related-posts-container {
    /* width: 100%; */
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 15px;
    margin-bottom: 10px;
}

.related-posts-content {
    margin-top: 10px;
}

.related-posts-title {
    font-size: 16px;
    color: var(--primary-red);
    font-weight: 700;
}

.related-posts-item {
    display: flex;
    margin-bottom: 5px;
}

.related-posts-item-img {
    width: 100px;
    height: 60px;
    border-radius: 6px;
    cursor: pointer;
}

.related-posts-item-desc {
    margin-left: 5px;
    font-size: 12px;
    cursor: pointer;
}

.no-related-posts-title {
    font-size: 14px;
    font-weight: 700;
    color: #888;
    text-align: center;
}