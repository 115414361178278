/*width: register*/
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: unset !important;
}

.css-14lo706 {
  font-size: 14px !important;
}

.css-14lo706 span {
  font-size: 14px !important;
}

.css-uhb5lp {
  max-width: unset !important;
}

/*login*/
.container-login {
  padding-bottom: 20px !important;
  margin-top: 1rem !important;
}

.register-from .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.register-from .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 16px !important;
  padding: 10px 14px !important;
}

.form-login {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  text-align: center;
}

.register-from {
  position: relative;

  min-height: 50rem;
}

.register-image {
  position: absolute;
  right: 0;
  left: 34%;
  top: 12%;
  bottom: 0;
}

.pointer {
  cursor: pointer;
}

.css-13xfq8m-MuiTabPanel-root {
  padding: 24px 10px !important;
}

.btn-register {
  width: 100%;
  /* height: 5rem; */
}

.title_cooperate_register {
  color: var(--primary-color);
}

.register-from .css-ypiqx9-MuiDialogContent-root {
  overflow-y: unset !important;
}

.choose {
  width: 100%;
  height: 2rem;
  padding-left: 10px;
  background-color: #fff;
  cursor: pointer;
  margin: 0.5rem 0;
  color: #666666;
  border: 1px solid #c9c9c9;
  font-weight: bold;
  font-size: 12px;
  border-radius: 5px;
  outline-color: var(--primary-blue);
}

.text-nofication {
  font-size: 11px;
  font-weight: 600;
  color: var(--primary-red);
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root,
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 12.8px !important;
}

.btn-login {
  width: 70px;
  height: 30px;
  display: flex;
  color: #ffff;
  margin: 0 5px;
  font-weight: 600;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #1976d2;
}

.btn-register-login .btn-login {
  font-size: 16px !important;
}

.btn-submit {
  width: 100%;
  height: 4rem;
}

.btn-submit:hover {
  color: red;
}

.box_login {
  padding: 15px;
  border-radius: 8px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.value_benefits span {
  display: block;
  font-size: 17px;
  font-weight: 400;
  margin: 3px 6px;
}

.title_login span {
  font-size: 22px;
  font-weight: 600;
}

.title_benefits span {
  font-size: 20px;
  font-weight: 500;
}

.title_login {
  text-align: center;
  margin: 10px 0px;
}

.botton-login {
  padding-top: 20px;
}

.content-login {
  font-size: 15px;
}

.to-forget-pass {
  text-align: right;
  padding-top: 10px;
}

.user-container {
  margin-top: 1rem;
}

.columnform-forget {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* Viền kẻ đơn xung quanh cột */
  padding: 20px;
  /* Khoảng cách giữa nội dung và viền */
  border-radius: 5px;
}

.forget-pass {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.form-forget {
  text-align: center;
}

.error-login {
  color: red;
  padding-top: 5px;
}

.btn-register {
  /* font-size: 1.5rem; */
  color: #fff;
  display: inline-block;
  font-weight: 600;
}

.nav-link {
  font-size: 14px !important;
}

.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list {
  display: block;
}

@media only screen and (max-width: 739px) {
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-weight: bold !important;
    font-size: 10px !important;
  }

  /* .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root, .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
        font-size: 10.8px !important;
    }

    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input, .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
        font-size: 16px !important;
        padding: 10px 10px !important;
    } */
}

@media only screen and (min-width: 740px) and (max-width: 1023px) {
}

@media only screen and (min-width: 1024px) {
}

@media only screen and (max-width: 641px) {
  .container-login form {
    min-width: unset !important;
  }

  .box_login {
    margin-top: 30px;
  }
}
