.cart-wrapper {
  width: 1264px;
  padding: 0 20px;
  margin: 16px auto;
}

.cart-conatiner {
  display: flex;
  margin-top: 0.5rem;
  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
}

.cart-left-container {
  width: 65%;
}

.cart-right-container {
  width: 34%;
}

.cart-conatiner-header {
  display: flex;
  align-items: center;
  /* padding: 0 10px; */
  justify-content: space-between;
  align-items: center;
}

/* .cart-container-header__delete-all {
  font-size: 17px;
  color: var(--primary-blue);
  cursor: pointer;
} */

.delete-product-btn {
  font-size: 12px;
  color: var(--primary-red);
  margin-right: 5px;
  cursor: pointer;
}

.cart-left {
  background-color: #fff;
  border-radius: 5px;
}

.cart-table-header {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 8px 15px;
  font-weight: 600;
  font-size: 14px;
  justify-content: space-between;
  border-bottom: 1px solid rgb(228, 229, 240);
}

.cart-table-header input[type="checkbox"] {
  margin-top: 0;
  margin-left: 0;
}

.cart-table-header p {
  margin-bottom: 0;
}

/* mobile check */

.checkbox-moblie {
  width: 20px;
  height: 20px;
}

.cart-moblie-header {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 8px 24px;
  font-weight: 600;
  font-size: 14px;
  justify-content: flex-start;
  border-bottom: 1px solid rgb(228, 229, 240);
}

.cart-name-moblie-product-header {
  margin-bottom: 0;
  margin-left: 14px;
  font-size: 18px;
}

.cart-table-header p {
  margin-bottom: 0;
}

.cart-image {
  width: 70px;
  height: 70px;
  border: 1px solid rgb(228, 229, 240);
}

.cart-item-price,
.total-price {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
}

.div-drum {
  height: 30px;
}

/* .cart-right-container {
  width: 37%;
} */

.cart-name-product-header {
  width: 45%;
}

.cart-content {
  margin-top: 10px;
  padding: 5px 8px;
}

.cart-content-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .cart-content-item p {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
} */

.cart-content-box-product {
  width: 50%;
}

.cart-content-title {
  width: 80%;
  font-weight: 600;
  font-size: 12px;
  padding: 2px 10px;
}

.cart-content-title p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 12px;
}

.gift-container-title {
  display: flex;
  font-size: 12px;
  align-items: center;
}

.gift-container-title p {
  margin-bottom: 0;
}

.gift-icon {
  fill: var(--primary-red);
  font-size: 14px;
}

.gift-container {
  display: flex;
  margin: 10px auto;
  width: 90%;
  background-color: rgb(243, 243, 247);
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
}

.arrow-icon {
  cursor: pointer;
}

.coupon-container {
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
}

.coupon-container-choose-coupon {
  cursor: pointer;
  font-size: 12px;
  color: rgb(25, 144, 255);
}

.payment-container {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
}

.payment-item {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}

.payment-vat {
  font-size: 12px;
  font-weight: 600;
  color: rgb(130, 134, 158);
}

.into-money {
  color: var(--primary-red);
  font-weight: 700;
}

.continue-btn {
  background-color: var(--primary-color);
  margin-top: 25px;
  border-radius: 5px;
  display: flex;
  font-size: 15px;
  align-items: center;
  height: 40px;
  justify-content: center;
  width: 100%;
}

.continue-btn-title {
  color: var(--primary-red);
  font-weight: 700;
  text-transform: uppercase;
}

.continue-btn-title:hover {
  color: var(--primary-red);
  font-weight: 700;
  text-transform: uppercase;
}

.cart-moblie {
  display: none;
}

.quantity-product {
  opacity: 1;
  height: 2rem;
  width: 6.3rem;
  border-radius: 5px;
  font-size: 20px;
  background: rgb(248, 248, 252);
  border: 1px solid rgb(228, 229, 240);
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-around;
}

.decrement-btn {
  height: 100%;
  width: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  border: none;
  background: transparent;
  cursor: not-allowed;
}

.increment-btn {
  height: 100%;
  width: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  border: none;
  background: transparent;
}

.checkbox-product {
  margin-left: 6px;
}

.delete-product {
  font-size: 14px;
  color: var(--primary-red);
  cursor: pointer;
}

.delete-product:hover {
  opacity: 0.7;
}

.input-quantity {
  width: 40px;
  font-size: 12px;
  padding: 7px;
  text-align: center;
  outline: none;
  border: none;
}

.total-price {
  min-width: 90px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cart-conatiner-emty {
  background-color: #fff;
  width: 100%;
  border-radius: 10px;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cart-conatiner-emty-icon {
  width: 75px;
  height: 75px;
}

.cart-conatiner-emty-title {
  font-size: 24px;
  margin: 10px 0;
}

.cart-conatiner-emty-link {
  font-size: 20px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: var(--primary-red);
  cursor: pointer;
  font-weight: 600;
}

.cart-conatiner-emty-link:hover {
  color: var(--primary-red);
  opacity: 0.7;
}

.coupon-container-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.coupon-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.choosen-coupon {
  margin-top: 8px;
  display: flex;
  border: 1px solid var(--primary-blue);
  padding: 8px;
  border-radius: 8px;
  column-gap: 20px;
}

.choosen-coupon__info {
  flex-grow: 1;
}

.choosen-coupon__top .choosen-coupon__values {
  font-size: 13px;
  font-weight: 500;
}

.choosen-coupon__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}

.choosen-coupon__bottom p {
  color: rgb(130, 134, 158);
  margin-bottom: 0;
}

.choosen-coupon__bottom div {
  color: rgb(25, 144, 255);
  cursor: pointer;
}

.coupon-name {
  margin-bottom: 0;
  font-size: 15px;
  color: var(--primary-red);
}

.applied-title {
  font-weight: 600;
  color: #82869e;
}

.promotions {
  background-color: var(--primary-color);
  color: var(--primary-red);
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
}

.slide-promotion {
  border-radius: 5px;
  height: 40px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  color: var(--primary-red);
  font-size: 18px;
  font-weight: 600;
}

.no-use-double {
  border-radius: 5px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  background-color: var(--primary-color);
  color: var(--primary-red);
  font-size: 16px;
  font-weight: 700;
}

/* modal */
.cart-delete-modal .modal-dialog {
  max-width: 400px;
  margin: 0 auto;
  top: 20%;
  font-size: 14px;
}

.dialog-header {
  display: flex;
  align-items: center;
}

.dialog-header__title {
  margin-bottom: 0;
  font-size: 16px;
  margin-left: 8px;
}

.dialog-footer__cancle,
.dialog-footer__confirm {
  font-size: 14px;
}

@media only screen and (max-width: 767px) {

  .cart-wrapper {
    width: 100%;

  }

  .cart-conatiner {
    display: block;
    min-height: 65%;
    width: 100%;
  }

  .cart-desktop {
    display: none;
  }

  .checkbox-product {
    margin-right: 6px;
    margin-left: 0;
  }

  .cart-moblie {
    display: block;
  }

  .cart-left-container {
    width: 100%;
  }

  .cart-conatiner-header {
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: center;
    height: 38px;
    text-transform: uppercase;
  }

  .cart-container-header__delete-all {
    /* font-size: 17px;
    color: var(--primary-blue);
    cursor: pointer; */
    display: none;
  }

  .delete-product-btn {
    display: none;
  }

  .cart-content-box-product {
    width: 100%;
    align-items: center;
  }

  .cart-content-item {
    display: block;
  }

  .cart-content-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80%;
    font-weight: 600;
    font-size: 12px;
    padding: 2px 10px;
  }

  .div-drum {
    display: none;
  }

  .cart-right-container {
    width: 100%;
  }

  .cart-right {
    margin-top: 10px;
  }

  .cart-conatiner-header h2 {
    font-size: 24px;
    text-transform: uppercase;
  }

  .price-product-mobile {
    margin-top: 10px;
    font-weight: 700;
    font-size: 16px !important;
    color: var(--primary-red);
  }

  .delete-product {
    font-weight: 600;
    position: relative;
  }

  .product-quantity-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cart-moblie-header {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 8px;
    font-weight: 600;
    font-size: 14px;
    justify-content: flex-start;
    border-bottom: 1px solid rgb(228, 229, 240);
  }

  .no-use-double {
    margin-top: 16px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {


  .cart-wrapper {
    width: 100%;

  }

  .cart-conatiner {
    display: block;
    margin-top: 1rem;
    width: 100%;
  }

  .cart-desktop {
    display: none;
  }

  .cart-moblie {
    display: block;
  }

  .cart-left-container {
    width: 100%;
  }

  .checkbox-product {
    margin-right: 6px;
    margin-left: 0;
  }

  .cart-conatiner-header {
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: center;
    height: 38px;
  }

  .cart-container-header__delete-all {
    display: none;
  }

  .delete-product-btn {
    display: none;
  }

  .cart-content-box-product {
    width: 100%;
    align-items: center;
  }

  .cart-content-item {
    display: block;
  }

  .cart-content-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80%;
    font-weight: 600;
    font-size: 12px;
    padding: 2px 10px;
  }

  .div-drum {
    display: none;
  }

  .cart-right-container {
    width: 100%;
  }

  .cart-right {
    margin-top: 10px;
  }

  .cart-content-item-box {
    padding: 16px;
  }

  .cart-conatiner-header h2 {
    font-size: 34px;
    text-transform: uppercase;
  }

  .product-quantity-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .no-use-double {
    margin-top: 16px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 1024px) {}