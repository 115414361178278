.promtion-detail-container {
    margin-top: 1rem;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 15px;
}

.news-detail-container-conetent div img {
    width: 55% !important;
    height: unset !important;
    object-fit: cover;
}

@media only screen and (max-width: 739px) {
    .news-detail-container-conetent div img {
        width: 100% !important;
        height: unset !important;
    }
}

@media only screen and (min-width: 740px) and (max-width: 1023px) {}

@media only screen and (min-width: 1024px) {}