.not-found-container {
    margin-top: 1rem;
    border-radius: 5px;
    display: flex;
    width: 100%;
    justify-content: center;
    min-height: 56vh;
    align-items: center;
    background-color: #fff;
}

.back-btn {
    background-color: var(--primary-color);
    color: var(--primary-red);
    margin-left: 25%;
    font-size: 14px;
    border-radius: 5px;
    font-weight: 700;
    padding: 10px 20px;
}

.back-btn:hover {
    color: var(--primary-red);
    opacity: 0.7;
}