.news-detail-container {
  margin-top: 1rem;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 15px;
}

.news-detail-container-title {
  text-align: center;
  font-weight: 700;
  color: var(--primary-blue);
}



.news-detail-container-conetent div .image {
  display: flex;
  justify-content: center;
}

.news-detail-container-conetent div .Table {
  width: 100% !important;
}