.slideshow {
    position: relative;
    /* margin-top: 12rem; */
}

.special-promotion-banner .ant-modal-content {
    background-color: unset !important;
    box-shadow: unset !important;
}

.special-promotion-banner .ant-modal-close {
    background: #fff !important;
    border-radius: 50%;
}



.popup-img {
    width: 100%;
    height: 80vh;
}

.close-popup-btn {
    font-size: 24px;
    position: absolute;
    top: -40px;
    right: -5%;
    cursor: pointer;
    color: #fff;
}

@media only screen and (max-width: 767px) {
    .slideshow {
        position: relative;
        margin-top: 1rem;
    }

    .popup-img {
        width: 80vw;
        height: unset;
    }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
    .popup-img {
        width: 80vw;
        height: unset;
    }
}

@media only screen and (min-width: 1024px) {
    .category-slide-mobile {
        display: none;
    }
}