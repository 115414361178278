.filter-from {
    height: 56rem;
}

.filter-from .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    padding: 8px 24px;
}

.filter-from .css-ypiqx9-MuiDialogContent-root {
    padding: 0 24px !important;
}

.filter-from-title {
    margin-bottom: 0;
}

.select-filter-product-box {
    border-bottom: 1px dashed #868686;
}

.no-product-nofication {
    text-align: center;
    font-weight: 700;
    color: #868686;
}

.select-box {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    row-gap: 3px;
    column-gap: 8px;
}

.down-filter {
    width: 100%;
    height: 2rem;
    padding-left: 10px;
    background-color: #fff;
    cursor: pointer;
    margin: 0.5rem 0;
    color: #666666;
    border: 1px solid #868686;
    font-weight: 700;
    border-radius: 5px;
    outline-color: var(--primary-blue);
}

.btn-compare.choose-product-btn-mobile {
    display: none;
}

.filter-price-and-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 15px;
}

.filter-price-box {
    display: flex;
    align-items: center;
}

.filter-title-name {
    font-size: 14px;
    font-weight: 700;
    color: #898989;
}

.filter-price-box-item {
    display: flex;
    margin-left: 5px;
}

.filter-btn {
    border: 1px solid;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 7px;
    border-radius: 5px;
    cursor: pointer;
}

.filter-btn:hover {
    background-color: var(--primary-blue);
    color: #fff;
}

.search-accessory-input {
    width: 20rem;
    height: 33px;
    padding: 0 10px;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 16px;
    outline: none;
    font-size: 12px;
}

.filter-product-box {
    height: 48vh;
    max-width: 61rem;
    overflow-y: scroll;
}

.filter-product-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 15px;
    border-bottom: 1px solid #a5a3a3;
}

.filter-product-left {
    display: flex;
    align-items: center;
}

.filter-product-img {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 5px;
    padding: 5px;
    width: 75px;
}

.filter-product-desc {
    margin-left: 10px;
}

.filter-product-desc-name {
    font-size: 14px;
    font-weight: 600;
    display: -webkit-box;
    min-height: 3rem;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    line-height: 1.6rem;
}

.filter-product-desc-price {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    color: var(--primary-red);
}

.choose-product-btn {
    border: 1px solid rgb(224, 224, 224);
    padding: 7px 20px;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    background-color: var(--primary-blue);
    font-weight: 600;
    cursor: pointer;
}

.choose-product-btn:hover {
    opacity: 0.7;
}

.build-configuration-pagination {
    border-top: 1px dashed #868686;
    align-items: center;
    height: 50px;
    padding-top: 15px;
    margin-top: 5px;
}

.filter-price-and-search-mobile {
    display: none;
}

.choose-product-btn-mobile {
    display: none;
}

@media only screen and (max-width: 481px) {
    .select-box {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        row-gap: 3px;
        column-gap: 0px;
    }

    .filter-product-desc-price {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        /* width: 275px; */
        font-weight: 700;
        color: var(--primary-red);
    }

    .filter-from {
        height: auto;
    }

    .filter-product-desc {
        margin-left: 10px;
        width: 72%;
    }

    .filter-product-left {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .down-filter {
        width: 95%;
        height: 3.5rem;
        padding-left: 10px;
        background-color: #fff;
        cursor: pointer;
        margin: 0.5rem 0;
        color: #666666;
        border: 1px solid #868686;
        font-weight: 700;
        border-radius: 5px;
        outline-color: var(--primary-blue);
    }

    .choose-product-btn-mobile {
        display: block;
        /* text-transform: uppercase; */
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
        color: var(--primary-blue);
        font-size: 16px;
        /* font-weight: 700; */
    }

    .filter-price-and-search-mobile {
        display: block;
    }

    .filter-price-and-search {
        display: none;
    }

    .filter-price-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .filter-title-name {
        font-size: 12px;
        font-weight: 700;
        color: #898989;
    }

    .filter-price-box-item {
        display: flex;
        margin: 10px 0;
        /* margin-left: 5px; */
    }

    .filter-btn {
        border: 1px solid;
        padding: 5px 10px;
        font-size: 12px;
        /* width: 12rem; */
        font-weight: 600;
        margin-right: 7px;
        border-radius: 5px;
        cursor: pointer;
    }

    .choose-product-btn {
        display: none;
    }

    .filter-product-box {
        height: 44vh;
        max-width: 61rem;
        overflow-y: scroll;
    }
}

@media only screen and (min-width: 481px) and (max-width: 641px) {
    .filter-product-box {
        height: 48vh;
        overflow-y: scroll;
    }

    .filter-price-and-search-mobile {
        display: none;
    }

    .choose-product-btn-mobile {
        display: none;
    }

}