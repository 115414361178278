.combo-product-container {
    background-color: #fff;
    margin-top: 10px;
    border-radius: 5px;
    padding: 10px 15px;
}

.product-combo-list {
    display: grid;
grid-template-columns: repeat(3, 1fr);
grid-template-rows: repeat(2, 1fr);
grid-column-gap: 15px;
grid-row-gap: 15px;
}

.combo-product-title {
    color: var(--primary-red);
    font-size: 18px;
    font-weight: 600;
}

.combo-product-box {
    width: 14rem;
}

.combo-product-add {
    font-size: 27px;
    margin: 0 5px;
}
.combo-product-box div div .box-btn-producthot.card-body{
    display: none;
}

.css-1pxa9xg-MuiAlert-message {
    padding: 10px 0;
    color: inherit;
    font-size: 12px;
    min-width: 0;
    overflow: auto;
}

.combo-add-cart {
    font-size: 15px;
    margin-top: 10px;
    background-color: var(--primary-blue);
    color: #fff;
    font-weight: 500;
    border: 1px solid #888;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

input[type=checkbox] {
    cursor: pointer;
    -ms-transform: scale(1.5); /* IE */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    transform: scale(1.5);
    margin-left: 5px;
    margin-top: 5px;
}

.combo-price {
    font-size: 20px;
    font-weight: 700;
    color: var(--primary-red );
}

.css-1dhf5yv-MuiPaper-root-MuiAlert-root {
    margin-top: 10px;
    padding: 2px 16px;
}

.combo-product-img-box {
    position: relative;
    display: inline-block;
    overflow: hidden;
    border-radius: 4px;
    height: 100px;
    width: 100px;
}

.combo-product-img {
    width: 100%;
    height: 100px;
    object-fit: contain;
    position: absolute;
    top: 0px;
    left: 9px;
}

.combo-product-box-title {
    margin: 8px 0px 0px;
    padding: 0px;
    border-style: none;
    border-width: 1px;
    border-color: unset;
    opacity: 1;
    color: rgb(67, 70, 87);
    font-weight: 500;
    text-decoration: unset;
    font-size: 12px;
    line-height: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-width: unset;
    min-width: unset;
    transition: color 0.3s ease 0s;
}

.combo-product-box-price {
    margin: 0px;
    padding: 0px;
    border-style: none;
    border-width: 1px;
    border-color: unset;
    opacity: 1;
    color: var(--primary-red);
    font-weight: 700;
    text-decoration: unset;
    font-size: 15px;
    line-height: 24px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: unset;
    max-width: unset;
    min-width: unset;
    transition: color 0.3s ease 0s;
}

.combo-product-add-cart-moblie {
    display: none;
}

@media only screen and (max-width: 767px) {
    .combo-product-box {
        width: 10rem;
    }

    .combo-product-add-cart-moblie {
        display: block;
    } 

    .combo-product-add-cart-desktop {
        display: none;
    }

    .combo-price {
        margin-top: 5px;
        display: flex;
        justify-content: flex-end;
        font-size: 20px;
        font-weight: 700;
        color: var(--primary-red );
    }
}
@media only screen and (min-width: 767px) and (max-width: 1023px) {
    .combo-product-box {
        width: 16rem;
    }

    .combo-product-add-cart-moblie {
        display: none;
    }
}