 .questions-container {
   margin-top: 32px;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
   padding: 10px 15px;
   background-color: #fff;
   border-radius: 8px;
   width: 100%;
   background-image: url(../../../../component/image/bg_write.png);
   background-clip: content-box;
   background-origin: content-box;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
 }

 .questions-category {
   display: flex;
   align-items: center;
   justify-content: center;
 }

 .questions-category ul {
   display: flex;
   gap: 20px;
 }

 .questions-category li {
   list-style-type: none;
   padding: 4px 16px;
   border: 1px solid #ccc;
   font-size: 15px;
   color: var(--text-gray);
   border-radius: 3px;
   position: relative;
   overflow: hidden;
   transition: color 0.3s ease;
 }

 .questions-category li:hover {
   background-color: var(--primary-blue);
   color: #fff;
   border: var(--primary-blue);
   cursor: pointer;
 }

 .questions-category li::before {
   content: "";
   position: absolute;
   top: 0;
   left: -75%;
   width: 50%;
   height: 100%;
   background: linear-gradient(120deg,
       rgba(255, 255, 255, 0) 0%,
       rgba(255, 255, 255, 0.5) 50%,
       rgba(255, 255, 255, 0) 100%);
   transform: skewX(-25deg);
   transition: all 0.5s;
 }

 .questions-category li:hover::before {
   left: 125%;
   transition: left 0.5s ease;
 }

 @media (min-width:320px) and (max-width: 640px) {

   .questions-form {

     transform: unset !important;
   }

   .questions-category ul {
     flex-wrap: wrap;
   }
 }

 @media (min-width: 740px) and (max-width: 1023px) {
   .questions-form {

     transform: unset !important;
   }

 }


 .questions-form {
   margin-top: 20px;
   max-width: 600px;
   transform: translateX(80%);
 }

 .questions-container h2 {
   position: relative;
   text-align: center;
   font-size: 28px;
   color: var(--primary-blue);
   font-weight: 600;
   letter-spacing: -1px;
   margin: 28px 0;
 }

 .questions-container h2::after {
   content: "";
   display: block;
   width: 10%;
   height: 3px;
   background: var(--primary-color);
   position: absolute;
   bottom: -6px;
   left: 50%;
   transform: translateX(-50%);
 }

 .questions-form__content {
   font-size: 14px;
   text-align: center;
   margin: 16px 0;
   color: var(--text-gray);
 }

 .questions-form__box {
   display: flex;
   align-items: center;
   margin-top: 10px;
 }

 .questions-form__input {
   flex: 1;
 }

 .questions-form label {
   display: block;
   margin-bottom: 5px;
   font-weight: 400;
   font-size: 14px;
   color: var(--text-gray);
   width: 24%;
 }

 .questions-form__box label span {
   color: var(--primary-red);
   font-weight: 300;
 }

 .error_msg {
   font-size: 14px;
   color: var(--primary-red);
 }

 .questions-form input,
 .questions-form textarea {
   width: 100%;
   padding: 10px;
   margin-bottom: 10px;
   border: 1px solid #ccc;
   border-radius: 0px;
   font-size: 14px;
   outline: none;
 }

 .questions-form input:focus,
 .questions-form textarea:focus {
   border-color: var(--primary-blue);
 }

 .form-buttons {
   display: flex;
   justify-content: center;
 }

 .form-buttons button {
   padding: 4px 32px;
   color: #003399;
   border: 1px solid #003399;
   cursor: pointer;
   border-radius: 5px;
 }

 .form-buttons button:hover {
   background-color: #003399;
   color: white;
   transition: all ease-in 0.2s;
 }

 .questions-form__submit {
   font-size: 16px;
 }

 .questions-form__box .error {
   font-size: 14px;
   color: var(--primary-red);
 }

 .questions-form__input select {
   width: 100%;
   padding: 10px;
   margin-bottom: 10px;
   border: 1px solid #ccc;
   border-radius: 0px;
   font-size: 14px;
 }

 .questions-form input:focus,
 .questions-form textarea:focus,
 .questions-form__input select:focus {
   border-color: var(--primary-blue);
 }

 .questions-list {
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 10px;
   margin-top: 16px;
 }

 .question-item {
   display: flex;
   align-items: center;
   padding: 10px;
   border-bottom: 1px solid #ccc;
 }

 .question-icon {
   color: var(--text-gray);
   margin-right: 10px;
   font-size: 20px;
 }

 .question-item p {
   margin: 0;
   color: var(--text-gray);
   font-size: 15px;
 }