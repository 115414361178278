.partner-detail__logo {
  max-width: 150px;
  margin: auto;
}

.partner-detail__logo img {
  width: 100%;
  height: 100%;
}

.tabs {
  margin-top: 10px;
}

.nav-tabs {
  justify-content: center;
}



.partner-news-grid_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0px 10px;
  gap: 10px;
}

.partner-news-grid-item {
  max-height: 310px;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 15px;
}

.partner-news-grid-item img {
  width: 100%;
  height: 60%;
  object-fit: fill;
}

.partner-news-body__title {
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.partner-news-body__content {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #718d8c;
}

.image.partner-right img {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.image {
  display: block;
  position: relative;
}

.image img {
  display: block;
  height: auto;
  width: 100%;
}

.image.partner-right {
  padding-top: 40%;
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    --bs-modal-width: 1000px;
  }
}


.partner-imgs-right img {
  width: 100%;
  height: 100%;
}

.partner-right img {
  border-radius: 8px;
}

.partner-right,
.img_main_item {
  border-radius: 8px;
  border: 1px solid #ccc;
}

@media only screen and (max-width: 481px) {

  .partner-news-grid_container {
    padding: 0px;
    grid-template-columns: repeat(2, 1fr);
  }

  .partner-news-body__title {
    font-size: 14px;
  }

  .partner-news-body__content {
    font-size: 12px;
  }

  /* 
  .partner-news-grid_container {
    gap: 40px;
    padding: 0 48px;
  } */
}

@media only screen and (min-width: 481px) and (max-width: 641px) {
  .partner-news-grid_container {
    gap: 24px;
    padding: 0 24px;
  }
}