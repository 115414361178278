.menu-block {
  width: 200px;
  background-color: #ffff;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  transition: border-radius 0.3s;
  border-radius: 8px;
}

.cate-item {
  padding: 5px 0;
  overflow-y: scroll;
  position: relative;
  overflow: unset;
}

.cate-item::-webkit-scrollbar {
  display: none;
}

.cate-items {
  padding: 5px 15px;
  color: black;
  font-weight: 500;
  font-size: 13px;
}

.cate-items:hover {
  background-color: #ebebeb;
  color: var(--primary-red);
}

.cate-items:hover .sub-menu {
  display: block;
}

.cate-item-title {
  margin-bottom: 0;
  margin-left: 8px;
}

.sub-menu {
  position: absolute;
  top: 0;
  left: 12.6rem;
  display: none;
  height: 100%;
}

.sub-menu::before {
  content: "";
  position: absolute;
  background-color: transparent;
  top: 0;
  left: -19px;
  width: 20px;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .menu-block {
    display: none;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .menu-block {
    display: none;
  }
}
