.menu-main {
  width: 100%;
  height: 100%;
  padding-bottom: 5px;
}

.carousel-container {
  width: 100%;
  height: 100%;
}

.menu-img {
  width: 100%;
  position: relative;
  min-height: 100%;
  image-rendering: pixelated;
}

.container-main {
  position: absolute;
  top: 24px;
}

.container-banner {
  position: absolute;
  bottom: -50px;
}

.menu-main .carousel.slide .carousel-control-prev {
  display: none;
}

.menu-main .carousel.slide .carousel-control-next {
  display: none;
}

.title-menu {
  font-size: 12px;
}

.ps-menu-button {
  height: 5px !important;
}

.title-menu {
  padding-left: 5px;
}

.button {
  width: 100%;
  text-align: center;
  height: 50px;
}

.grid-item {
  display: inline-block;
  overflow: hidden;
}

.carousel-indicators [data-bs-target] {
  background-color: var(--primary-blue);
}
@media only screen and (max-width: 767px) {
  .menu-main {
    padding-right: 2%;
    padding-left: 2%;
  }
  .slideshow__image {
    height: 163px;
    width: 100%;
    image-rendering: pixelated;
    /* object-fit: cover; */
    border-radius: 10px;
  }
  .menu-slideshow {
    display: none;
  }
  .menu-slideshow-mobile {
    display: inline;
  }
  .mini-banner {
    display: none;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .slideshow__image {
    /* height: 450px; */
    width: 100%;
    object-fit: cover;
  }
  .menu-slideshow {
    display: inline;
    image-rendering: pixelated;
  }
  .menu-slideshow-mobile {
    display: none;
  }
  .mini-banner {
    display: inline;
  }
}
@media only screen and (min-width: 1024px) {
  .slideshow__image {
    height: 500px;
    width: 100%;
    /* object-fit: cover; */
  }
  .menu-slideshow {
    display: inline;
  }
  .menu-slideshow-mobile {
    display: none;
  }
  .mini-banner {
    display: inline;
  }
}
