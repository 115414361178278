.model {
  z-index: 1002;
}

.img-product-review {
  width: 20rem;
  height: 20rem;
  border: 1px solid #f0f0f0;
}

.titile-product-review {
  font-size: 15px !important;
}

.review-product-priceOld {
  text-decoration: line-through;
  font-size: 14px;
  font-weight: 500;
  color: #999;
  margin-right: 2px;
  margin-bottom: 0;
}

.detailed-specificate-model-content-review {
  height: 68vh;
  max-width: 54rem;
  width: 54rem;
}


.no-tech-title {
  text-align: center !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #999 !important;
}

.box-product-review {
  height: 23rem;
}

.review-product-price {
  color: var(--primary-red);
  margin-bottom: 0;
  font-weight: 600;
  font-size: 20px;
  margin-right: 2px;
}

.btn-see-detail {
  width: 40%;
  height: 50px;
  border-radius: 5px;
  background-color: var(--primary-blue);
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-review-model {
  display: flex;
  align-items: center;
}

.btn-see-detail-title {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.btn-see-detail-title:hover {
  color: #fff;
}

.btn-add-to-cart-title:hover {
  color: #fff;
}

.btn-add-to-cart-title {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.btn-add-to-cart {
  width: 40%;
  height: 50px;
  border-radius: 5px;
  background-color: var(--primary-red);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.close-model {
  font-size: 18px;
  position: absolute;
  right: 10px;
}

.detailed-specificate-model-content-moblie {
  display: none;
}

.detailed-specificate-model-content-review {
  display: block;
}

@media only screen and (max-width: 767px) {
  .img-product-review {
    margin-top: 20px;
    width: 100%;
    height: 26rem;
  }
  .product-review-model {
    display: block;
  }

  .detailed-specificate-model-content-moblie {
    display: block;
  }

  .detailed-specificate-model-content-review {
    display: none;
  }
}
