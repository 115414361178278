.sidebar-product-container {
  font-size: 14px;
  display: flex;

}

.sidebar-product-container-title {
  font-size: 14px;
  font-weight: 700;
}

.sidebar-product-container-title:hover,
.sidebar-product-checkbox-name:hover {
  color: var(--primary-red);
}

.sidebar-product-checkbox-name {
  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
}

.has-scroll .filter-list--hang {
  gap: 6px;
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: repeat(5, minmax(0, 1fr));
  margin: 0 0 10px;
}

.c-btnbox img {
  display: flex;
  width: auto;
  max-height: 25px;
  max-width: 98%;
  object-fit: contain;
  justify-content: center;
}

.c-btnbox .c-btnbox-filter-hang {
  display: flex;
  width: auto;
  max-height: 25px;
  max-width: 98%;
  object-fit: contain;
  justify-content: center;
}

.filter-list.filter-list--hang {
  gap: 6px;
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: repeat(5, minmax(0, 1fr));
  margin: 0 0 10px;
}

.filter-show.show-total .filter-button {
  bottom: 0;
  margin: 0;
  position: sticky;
  width: 100%;
  z-index: 2;
}

.filter-button {
  background-color: #fff;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.05);
  font-size: 0;
  margin: 8px -10px 0;
  padding: 8px 10px;
  text-align: center;
  /* display: none; */
}

.btn-filter-close,
.btn-filter-readmore {
  border-radius: 4px;
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  margin: 0 5px;
  padding: 10px 0;
  text-align: center;
  width: 170px;
  cursor: pointer;
  position: relative;
}

.btn-filter-readmore:hover {
  opacity: 0.7;
}

.btn-filter-close:hover {
  background-color: red;
  color: white;
  border: none;
  opacity: 0.8;
}

.btn-filter-close {
  border: 1px solid #dd1c1a;
  color: #dd1c1a;
}

.btn-filter-readmore {
  background-color: #288ad6;
  color: #fff;
}

.box-choose-title {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  font-size: 14px;
}

.has-choose {
  font-weight: 600;
  margin-bottom: 0;
  margin-right: 10px;
}

.box-choose-item {
  padding: 10px;
  margin-right: 7px;
  margin-bottom: 7px;
  color: #333;
  border: 1px solid #666;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-height: 30px;
}

.delete-filter {
  font-weight: 700;
  cursor: pointer;
}

.delete-filter:hover {
  color: var(--primary-red);
}

/* check point for mobile */
@media only screen and (max-width: 481px) {
  .sidebar-product-container {

    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .select-box {
    min-width: 120px !important;
  }

  .filter-show {
    background-color: #fff;
    border-top: 1px solid #ddd;
    max-width: 640px;
    min-width: 100% !important;
    left: 0;
    position: absolute;
    padding: 10px !important;
    top: 53px !important;
    width: 100% !important;
    white-space: normal;
    z-index: 1 !important;
    max-height: 45vh;
    overflow: hidden;
    overflow-y: auto;
  }

  .filter-total {
    background-color: pink;
    left: 0;
    position: absolute !important;
    padding-left: 0px;
    padding-bottom: 9px;
    top: 9.5px;
    z-index: 3;
    outline: 1px solid red;
    display: none !important;
  }

  .filter-item {
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    margin-right: 8px;
    position: unset !important;
    padding-bottom: 10px;
  }

  .block-scroll-main::after {
    background: linear-gradient(to right,
        rgba(255 255 255/0.3),
        rgba(255 255 255/0.8),
        #fff);
    content: "";
    height: 42px;
    position: absolute;
    top: 2px;
    right: -2px;
    width: 40px;
  }

  .block-scroll {
    padding: 10px 0 0;
    position: relative;
  }

  .box-filter {
    background: #fff;
    font-size: 0;
    margin-left: 8px;
  }

  .block-scroll-main {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }

  .show-total {
    background-color: #fff !important;
    height: 100vh !important;
    left: 0 !important;
    right: 0;
    bottom: 0px;
    margin: 0 auto;
    overflow: auto;
    position: fixed !important;
    padding: 23px 0 23px !important;
    top: 140px !important;
    width: 100% !important;
    max-width: 640px !important;
    z-index: 9 !important;
  }

  /* .scrolling_inner {
        position: unset !important;
    } */

  .show-total .show-total-item {
    width: auto !important;
    float: left !important;
    max-width: 100% !important;
  }

  .close-popup-total {
    display: none !important;
  }

  .flt-fixbtn {
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    left: 0;
    right: 0;
    /* margin: 0 auto; */
    position: fixed;
    padding: 6px 10px;
    top: 140px;
    width: 100%;
    max-width: 640px;
    z-index: 13;
  }

  .btn-closefilter {
    display: block !important;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    color: #333;
    float: right;
    font-size: 14px;
    line-height: 21px;
    padding: 5px 5px 5px 0;
    position: relative;
    text-align: right;
    width: 65px;
  }

  .btn-closefilter::before,
  .btn-closefilter::after {
    background-color: #333;
    content: "";
    left: 15px;
    height: 10px;
    position: absolute;
    top: 10px;
    width: 1px;
  }

  .btn-closefilter::before {
    transform: rotate(45deg);
  }

  .btn-closefilter::after {
    transform: rotate(-45deg);
  }

  .has-scroll .filter-list--hang {
    display: grid;
    grid-auto-rows: minmax(min-content, max-content);
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 6px;
    margin-bottom: 10px;
  }

  .filter-list.filter-list--hang {
    display: grid;
    grid-auto-rows: minmax(min-content, max-content);
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 6px;
    margin-bottom: 10px;
  }

  .filter-show.show-total {
    max-height: 70vh !important;
  }

  .filter-show.show-total .filter-button {
    position: sticky;
    display: flex;
    bottom: 80%;
    width: 100%;
    height: 53px;
    z-index: 99;
  }

  .show-total-main {
    padding: 80px 0 10px !important;
  }
}

@media only screen and (min-width: 740px) and (max-width: 1023px) {
  .sidebar-product-container {

    width: 100%;
    margin-top: 10px;
  }

  .select-box {
    min-width: 120px !important;
  }

  .filter-show {
    background-color: #fff;
    border-top: 1px solid #ddd;
    max-width: 640px;
    min-width: 100% !important;
    left: 0;
    position: absolute;
    padding: 10px !important;
    top: 53px !important;
    width: 100% !important;
    white-space: normal;
    z-index: 1 !important;
    max-height: 45vh;
    overflow: hidden;
    overflow-y: auto;
  }

  .filter-total {
    background-color: #fff;
    left: 0;
    position: absolute !important;
    padding-left: 0px;
    padding-bottom: 9px;
    top: 9.5px;
    z-index: 3;
  }

  .filter-item {
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    margin-right: 8px;
    position: unset !important;
    padding-bottom: 10px;
  }

  /* .scrolling_inner.scroll-left::before {
        background: linear-gradient(to left,rgba(255 255 255/.3),rgba(255 255 255/.8),#fff);
        content: '';
        left: 70px;
        height: 55px;
        position: absolute;
        top: -10px;
        width: 30px;
        z-index: 1;
    } */
  .block-scroll-main::after {
    background: linear-gradient(to right,
        rgba(255 255 255/0.3),
        rgba(255 255 255/0.8),
        #fff);
    content: "";
    height: 42px;
    position: absolute;
    top: 2px;
    right: -2px;
    width: 40px;
  }

  .box-filter {
    background: #fff;
    font-size: 0;
    margin-left: 100px;
  }

  .block-scroll-main {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }

  .show-total {
    background-color: #fff !important;
    height: 100vh !important;
    left: 0 !important;
    right: 0;
    bottom: 0px;
    margin: 0 auto;
    overflow: auto;
    position: fixed !important;
    padding: 23px 0 23px !important;
    top: 140px !important;
    width: 80% !important;
    max-width: 640px !important;
    z-index: 9 !important;
  }

  .btn-closefilter {
    display: none;
  }

  .filter-show.show-total .filter-button {
    bottom: -25px;
    margin: 0;
    position: sticky;
    width: 100%;
    z-index: 2;
  }
}

.btn-closefilter {
  display: none;
}

.filter-item,
.filter-total {
  display: inline-block;
  font-size: 14px;
  line-height: 17px;
  margin-right: 8px;
  position: relative;
  padding-bottom: 10px;
  cursor: pointer;
}

.filter-item__title {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 10px;
  cursor: pointer;
  z-index: 99;
}

.filter-item__title span {
  padding-right: 12px;
  position: relative;
}

.filter-total .filter-item__title span::before {
  display: none;
  position: unset;
  padding: 2px;
  outline: 1px solid orange;
}

.filter-item__title span::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #333;
  content: "";
  height: 0;
  position: absolute;
  top: 6px;
  right: 0;
  width: 0;
}

.filter-item__title.active.showing {
  z-index: 4;
}

.filter-item__title.active {
  border: 1px solid #4a90e2;
  position: relative;
  z-index: 2;
}

.filter-item .filter-show.filter-show--right {
  left: auto;
  right: 0;
}

.filter-item__title.showing.active .arrow-filter {
  width: 27px;
  height: 14px;
  top: 30px;
  left: 25px;
  position: absolute;
  overflow: hidden;
  cursor: pointer;
}

.filter-item__title.showing.active .arrow-filter::before {
  content: "";
  position: absolute;
  width: 27px;
  height: 27px;
  background: #fff;
  transform: rotate(45deg);
  top: 10px;
  left: 0;
  box-shadow: -2px -2px 5px -4px;
  outline: 1px solid gray;
  cursor: pointer;
}

.filter-show {
  /* display: none; */
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  max-width: 600px;
  min-width: 382px;
  left: 0;
  position: absolute;
  padding: 10px 10px 0 10px;
  top: 45px;
  width: max-content;
  white-space: normal;
  z-index: 3;
}

.filter-list {
  display: flex;
  flex-wrap: wrap;
}

.close-popup-box {
  position: sticky;
  top: 5px;
  right: 5px;
  width: 100%;
  height: 40px;
}

.close-popup-total {
  display: block;
  position: absolute;
  top: 5px;
  right: 10px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 10px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.c-btnbox {
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  color: #333;
  display: flex;
  font-size: 14px;
  justify-content: center;
  line-height: 17px;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 8px 9px;
}

.c-btnbox:hover {
  cursor: pointer;
  border: 1px solid #2f80ed;
  color: #2f80ed;
}

.c-btnbox.check {
  border: 1px solid #4a90e2;
  color: #4a90e2;
}

.show-total-main {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  padding: 15px 0 10px;
}

.filter-show.show-total {
  overflow-y: auto;
  padding: 0;
  width: 900px;
  max-width: 900px;
  max-height: 65vh;
}

.show-total-txt {
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 5px;
}

.show-total-item {
  padding: 10px 20px;
}

.filter-border {
  border-top: 1px solid #e0e0e0;
  width: 100%;
}

.show-total .show-total-item {
  width: auto;
  float: left;
  max-width: 33%;
}

.show-total .show-total-item.warpper-manu-inside {
  width: 100%;
  clear: both;
  max-width: unset;
}

.clearfix {
  display: block;
}

section {
  max-width: 1200px;
  width: 100%;
  min-width: 980px;
  position: relative;
}

.block-scroll {
  z-index: 4;
  position: relative;
  padding: 10px 0 0;
  background-color: #fff;
}

.scrolling_inner {
  position: relative;
}