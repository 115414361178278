.footer {
  margin-top: 20px;
  padding-top: 20px;
  background-color: var(--primary-blue);
}

.footer-container-banner-img {
  width: 100%;
}

.box-bank {
  height: 50px;
  background: #fff;
  margin-top: 10px;
}

.box-bank label {
  margin: 0;
  width: 245px;
  background: var(--primary-color);
  font-weight: 600;
  color: var(--primary-blue);
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  position: absolute;
  font-size: 20px;
}

.box-bank label::after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  border-bottom: 25px solid transparent;
  border-top: 25px solid transparent;
  border-left: 20px solid #ffd618;
}

/* label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
} */

.box-bank-list {
  padding-left: 20%;
  justify-content: space-around;
  display: flex;
}

.box-bank-img {
  width: 100%;
  height: 50px;
}

.footer-container-logan {
  margin-top: 15px;
  background-color: #fff;
  height: 50px;
  display: flex;
  align-items: center;
}

.footer-container-logan-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer-container-logan-item {
  display: flex;
  align-items: center;
}

.footer-container-logan-icon {
  width: 30px;
}

.footer-container-logan-text {
  font-size: 12px;
  font-weight: 700;
  color: var(--primary-blue);
  margin-left: 10px;
}

.footer-container-list-link {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.footer-container-list-list {
  margin: 15px 0;
}

.footer-container-link {
  font-size: 16px;
  cursor: pointer;
  margin: 10px 0;
  color: #fff;
  text-decoration: none;
}

.footer-container-link:hover {
  color: var(--primary-red);
}

.support-title {
  font-size: 24px;
  font-weight: 600;
  color: var(--primary-color);
}

.support-list {
  margin-bottom: 0;
  display: block;
  margin-left: -15px;
}

.support-list-item {
  font-size: 18px;
  color: #fff;
}

.footer-container-list-social-network {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.social-network-icon {
  font-size: 30px;
  color: #fff;
}

.footer-container-fb {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.loaction-icon,
.location-mail {
  font-size: 45px;
  color: var(--primary-color);
}

.footer-container-address {
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
}

.footer-container-address-item {
  display: flex;
  align-items: center;
}

.footer-container-address-item-text {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  margin-left: 10px;
}

.footer-container-address-item-title {
  color: var(--primary-color);
  font-size: 20px;
}

/* .newsletter-frm {
  position: relative;
}

.newsletter-frm .form-control {
  height: 40px;
  box-shadow: none;
  border: none;
} */

.newsletter-frm {
  display: flex;
  flex-direction: column;
  /* background-color: #003c85; */
  /* padding: 10px 15px; */
  border-radius: 8px;
  color: #fff;
  align-items: flex-start;
}

.newsletter-icon-text {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon-wrapper {
  background-color: #ffcc00;
  padding: 10px;
  border-radius: 50%;
}

.newsletter-icon {
  color: #003c85;
  font-size: 24px;
}

.newsletter-title {
  font-weight: bold;
  font-size: 16px;
}

.newsletter-description {
  font-size: 14px;
}

.newsletter-input-btn {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: 10px;
}

.form-control {
  display: block;
  width: 280px;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.sendmail-btn {
  background-color: #ffcc00;
  padding: 8px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #003c85;
}

.key-Search-container {
  background: #204db1;
  color: #fff;
  display: flex;
  height: 50px;
  align-items: center;
}

.key-Search-container-title {
  margin-bottom: 0;
}

.key-Search-container-list {
  display: flex;
  height: 40px;
  align-items: center;
  font-size: 16px;
  color: #fff;
}

.key-Search-container-items {
  margin-bottom: 0;
}

.key-Search-container-item {
  margin-right: 25px;
  color: #fff;
  cursor: pointer;
}

.key-Search-container-item:hover {
  color: var(--primary-color);
}

.footer-container-logo {
  background-color: #fff;
  padding: 15px 0;
}

.logo-img {
  width: 220px;
}

.footer-container-list-partner-img {
  width: 75px;
  margin-right: 10px;
}

.footer-container-logo-partner-container {
  display: flex;
  justify-content: space-between;
}

.footer-container-list-partner-title {
  color: #e86e26;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.logo-desc {
  width: 210px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 13px;
}

.footer-container-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 1003;
  background-color: #ffc107;
}

.footer-phone {
  /* padding-bottom: 6rem; */
}

.footer-main {
  margin-top: 5px !important;
  margin-left: 5px !important;
}

.footer-icon-item {
  color: white;
  font-size: 12px;
}

.icon-footer {
  color: white;
  text-align: center;
}

.top-footer {
  background-color: #0459aa;
  width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: -10px;
  padding: 20px;
  color: white;
}

.text-content-footer {
  color: white;
}

.arrowup {
  padding-top: 10px;
}

.dropup-content {
  position: absolute;
  bottom: 60px;
  background-color: #ffc107;
  width: 600px;
  height: 60px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  right: 0;
}

.notification-title {
  text-align: center;
  margin: 10px;
  text-transform: uppercase;
  color: var(--primary-red);
  font-weight: 700;
}

@media only screen and (max-width: 481px) {
  .box-bank label {
    display: none;
    margin: 0;
    width: 245px;
    background: var(--primary-color);
    font-weight: 600;
    color: var(--primary-blue);
    height: 50px;
    line-height: 50px;
    padding: 0 15px;
    position: absolute;
    font-size: 20px;
  }

  .box-bank-list {
    padding-left: 0;
    justify-content: space-around;
    align-items: center;
    display: flex;
  }

  .box-bank-img {
    width: 100%;
    height: 40px;
  }

  .footer-container-address {
    display: block;
    margin-bottom: 25px;
  }

  .key-Search-container {
    background: #204db1;
    color: #fff;
    display: block;
    height: 50px;
    align-items: center;
  }

  .key-Search-container-list {
    display: block;
    height: 40px;
    align-items: center;
    font-size: 12px;
    color: #fff;
  }

  .footer-container-address-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .footer-container-list-link {
    display: block;
    /* align-items: center; */
  }

  .footer {
    padding-top: 25px;
    margin-bottom: 50px;
  }

  .footer-container-fb {
    display: none;
  }

  .newsletter-frm {
    display: none;
  }

  .footer-container-logo-partner-container {
    display: block;
  }

  .footer-container-list-partner {
    margin: 10px 0;
  }

  .footer-container-logan {
    display: none;
  }

  .key-Search-container-items {
    margin-bottom: 0;
    padding-left: 0;
    margin-left: 2px;
  }

  /* .key-Search-container-list {
  display: flex;
  height: 40px;
  align-items: center;
  font-size: 12px;
  color: #fff;
} */
}

@media only screen and (min-width: 481px) and (max-width: 641px) {
  .footer {
    padding-top: 25px;
    margin-bottom: 50px;
  }

  .footer-container-fb {
    display: none;
  }

  .newsletter-frm {
    display: none;
  }

  .footer-container-logo-partner-container {
    display: block;
  }

  .footer-container-list-partner {
    margin: 10px 0;
  }

  .key-Search-container-list {
    display: flex;
    height: 40px;
    align-items: center;
    font-size: 12px;
    color: #fff;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .footer-container-list-link {
    flex-flow: wrap;
  }
}


.box-image-certificate {
  width: 200px;
}