/* .choose-coupon-model {
    min-width: 50rem;
} */

.choose-coupon-title {
  font-size: 20px !important;
  font-weight: 700 !important;
}

.box-search {
  flex: 1 1 auto;
  margin: 0px 0.75rem 0px 0px !important;
}

.choose-coupon-box-search {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-flow: wrap;
  border-style: none;
  border-width: 1px;
  border-color: unset;
  opacity: 1;
  background-color: white;
}

.search-input {
  border-style: solid;
  border-radius: 0.25rem;
  border-width: 1px;
  opacity: 1;
  height: 40px;
  padding: 0rem 0.75rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-color: rgb(224, 224, 224);
}

.search-input input:active {
  border-color: var(--primary-red);
}

.search-input input {
  flex: 1 1 0%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 13px;
  color: rgb(67, 70, 87);
  background-color: inherit;
}

.apply-btn {
  height: 40px;
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  color: var(--primary-red);
  font-weight: 600;
  font-size: 14px;
  padding: 0 10px;
  cursor: pointer;
  border-radius: 5px;
}

.apply-btn:hover {
  opacity: 0.7;
}

.choose-coupon-content {
  margin-top: 10px;
}

.choose-coupon-content-title {
  font-size: 14px;
  /* margin-bottom: 0; */
  font-weight: 700;
}

.choose-coupon-content-point {
  margin-left: 5px;
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-red);
}

.coupon-icon-gift {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: var(--primary-red);
  height: 70px;
  background-color: rgb(243, 243, 247);
  font-size: 20px;
}

.coupon-item-container {
  max-height: 32rem;
  overflow: auto;
}

.coupon-item {
  width: 100%;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid rgba(219, 219, 225, 1);
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.coupon-item-name {
  width: 100%;
  padding: 4px 12px;
  color: var(--text-gray);
  font-size: 13px;
}

.coupon-item-name p {
  margin-bottom: 4px;
}

.coupon-item__name {
  color: var(--primary-blue);
  font-weight: 600;
  font-size: 14px;
}

.coupon-item__expire {
  font-weight: 600;
}

.apply-coupon {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-red);
  cursor: pointer;
}

.apply-coupon:hover {
  opacity: 0.7;
}

.close-choose-coupon-model {
  border: 1px solid #444;
  padding: 8px 18px;
  font-size: 12px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 20px;
}

.close-choose-coupon-model:hover {
  opacity: 0.7;
}

@media only screen and (max-width: 767px) {
  .choose-coupon-model {
    min-width: min-content;
  }

  .choose-coupon-title {
    font-size: 18px !important;
    font-weight: 700 !important;
  }

  .coupon-item-container {
    min-height: 32rem;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1023px) {
}
@media only screen and (min-width: 1024px) {
}
