.build-configuration-container {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.build-configuration-left {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
}

.build-configuration-right {
  width: 30%;
  background-color: #fff;
  border-radius: 5px;
  height: fit-content;
}

.build-configuration-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.save-config-btn {
  background-color: var(--primary-blue);
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  border-style: none;
  font-weight: 600;
  height: 32px;
  border-radius: 5px;
  margin-right: 13px;
  margin-top: 5px;
  padding: 0 6px;
  cursor: pointer;
}

.save-config-btn:hover {
  opacity: 0.7;
}

.down-config {
  width: 12rem;
  height: 3rem;
  padding-left: 10px;
  background-color: #fff;
  cursor: pointer;
  margin: 0.5rem 0;
  color: #666666;
  border: 1px solid #868686;
  font-weight: 700;
  border-radius: 5px;
  outline-color: var(--primary-blue);
}

.choose-option-save {
  display: flex;
  margin-right: 10px;
}

.down-config-option {
  font-size: 14px;
}

.pdf-icon {
  color: #000;
}

.Configurations-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.accessory-img {
  width: 80px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin: 0 10px;
  cursor: pointer;
}

.Configurations-left {
  display: flex;
  align-items: center;
}

.Configurations-name {
  font-size: 14px;
  color: #838383;
  font-weight: 600;
  width: 10rem;
}

.Configurations-title {
  font-size: 14px;
}

.Configurations-choose-option-btn {
  background-color: var(--primary-blue);
  color: #fff;
  margin-right: 10px;
  width: 80px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  /* padding: 6px 15px; */
}

.Configurations-choose-option-btn:hover,
.get-advice-btn:hover {
  opacity: 0.7;
}

.empty-cart-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 29rem;
}

.empty-cart-img {
  width: 100%;
  height: 160px;
}

.get-advice-btn {
  width: 80%;
  background-color: blue;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  padding: 5px 0;
  margin: 10px auto;
}

.choose-option-save-mobile {
  display: none;
}

.Configurations-desc {
  width: 50vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Configurations-name-product {
  /* width: 240px; */
  font-size: 12px;
  font-weight: 600;
}

.Configurations-quantity {
  margin: 0 15px;
}

.Configurations-total-price {
  color: var(--primary-red);
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
  min-width: 90px;
  display: -webkit-box;
  min-height: 3rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  line-height: 3rem;
}

.provisional-costs-container {
  display: flex;
  height: 40px;
  align-items: center;
}

.provisional-costs {
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-red);
  margin: 0 15px;
}

.add-to-cart-button {
  background-color: var(--primary-blue);
  cursor: pointer;
  color: #fff;
  font-size: 15px;
  padding: 5px 10px;
  font-weight: 700;
  border-radius: 5px;
}

.add-to-cart-button:hover {
  opacity: 0.7;
}

.Configurations-quality-mobile {
  display: none;
}

.Configurations-quality-tablet {
  display: flex;
}

.Configurations-choose-action {
  display: flex;
  float: right;
}

@media only screen and (max-width: 481px) {
  .build-configuration-container {
    display: block;
  }

  .build-configuration-left {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
  }

  .build-configuration-right {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 5px 0;
    margin-top: 10px;
    height: -moz-fit-content;
    height: fit-content;
  }

  .Configurations-item {
    display: block;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: space-between; */
    width: 100%;
    margin-bottom: 20px;
  }

  .Configurations-choose-action {
    display: flex;
    float: right;
  }

  .empty-cart-img {
    width: 70%;
    height: 160px;
  }

  .choose-option-save {
    display: none;
  }

  .choose-option-save-mobile {
    display: block;
    justify-content: flex-end;
    margin-right: 10px;
    padding-top: 6px;
  }

  .Configurations-left {
    display: block;
    align-items: center;
  }

  .save-config-btn {
    width: fit-content;
  }

  /* .Configurations-choose-option-btn {
        display: none;
    } */

  .Configurations-quality-mobile {
    float: right;
    margin-top: 11px;
    display: flex;
  }

  .Configurations-quality-tablet {
    display: none;
  }
}

@media only screen and (min-width: 481px) and (max-width: 641px) {
  .build-configuration-container {
    display: block;
  }

  .build-configuration-left {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
  }

  .build-configuration-right {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 10px;
    height: -moz-fit-content;
    height: fit-content;
  }

  .empty-cart-img {
    width: 70%;
    height: 160px;
  }

  .choose-option-save {
    display: none;
  }

  .choose-option-save-mobile {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    padding-top: 6px;
  }

  .Configurations-choose-option-btn {
    display: none;
  }

  .Configurations-quality-mobile {
    display: none;
  }

  .Configurations-quality-tablet {
    display: flex;
  }
}