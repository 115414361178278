th:not(:first-child),
td:not(:first-child) {
    border-left: none;
}

.detailed-specificate-model-content {
    height: 45rem;
    overflow-y: scroll;
    width: 70rem;
}

.detailed-specificate-container {
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 15px;
    margin-bottom: 10px;
}

.detailed-specificate-title {
    font-size: 18px;
    /* color: var(--primary-red); */
    font-weight: 700;
    margin-bottom: 2rem;
}

.detailed-specificate-col-title {
    font-size: 12px !important;
    padding: 10px !important;
}

.detailed-specificate-main-title {
    font-size: 12px !important;
    padding: 10px !important;
    font-weight: 700 !important;
    color: rgb(130, 134, 158) !important;
}

.updated {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    color: #888;
}

.detailed-specificate-col-title-main {
    font-size: 12px !important;
    padding: 9px !important;
    color: rgb(130, 134, 158) !important;
}

.detailed-specificate-table {
    position: relative;
}

.detailed-specificate-see-more {
    position: absolute;
    width: 93%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 41px;
    font-size: 12px;
    font-weight: 700;
    color: var(--primary-blue);
    bottom: 0;
    cursor: pointer;
    background: linear-gradient(to bottom, rgba(255 255 255/0), rgba(255 255 255/62.5), rgba(255 255 255/1));
}

.detailed-specificate-see-more-text {
    margin: 0;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 4px 13px;
    width: fit-content;
    height: fit-content;
    /* border: 1px solid; */
}

@media only screen and (max-width: 767px) {
    .detailed-specificate-see-more {
        position: absolute;
        width: 93%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 41px;
        font-size: 12px;
        font-weight: 700;
        color: var(--primary-blue);
        bottom: 0;
        cursor: pointer;
        background: linear-gradient(to bottom, rgba(255 255 255/0), rgba(255 255 255/62.5), rgba(255 255 255/1));
    }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
    .detailed-specificate-see-more {
        position: absolute;
        width: 97%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 41px;
        font-size: 12px;
        font-weight: 700;
        color: var(--primary-blue);
        bottom: 0;
        cursor: pointer;
        background: linear-gradient(to bottom, rgba(255 255 255/0), rgba(255 255 255/62.5), rgba(255 255 255/1));
    }
}