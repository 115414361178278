/* .complete-buy-container {
    margin-top: 15rem;
    padding: 10rem;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.complete-buy-desc {
    margin-top: 10px;
    text-align: center;
    font-size: 14px
}

.complete-buy-desc-title {
    color: var(--primary-red);
    font-weight: 700;
}

.complete-buy-desc-sub-title {
    margin-top: 5px;
    font-weight: 700;
} */

.order-confirmation {
  font-size: 14px;
  padding: 20px;
  max-width: 700px;
  /* margin-top: 32px; */
  margin: 32px auto;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.order-confirmation__header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.order-confirmation__icon {
  font-size: 70px;
  color: #9fbc6a;
}

.order-confirmation__message p {
  margin-bottom: 0;
}

.order-confirmation__details {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.order-confirmation__info,
.order-confirmation__shipping-address,
.order-confirmation__payment-method,
.order-confirmation__delivery-method {
  flex: 1 1 calc(50% - 20px);
}

.order-confirmation__info h3,
.order-confirmation__shipping-address h3,
.order-confirmation__payment-method h3,
.order-confirmation__delivery-method h3 {
  font-size: 16px;
  margin-bottom: 8px;
}

.order-confirmation__summary h3 {
  font-size: 16px;
  margin-bottom: 12px;
}

.order-confirmation__list {
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.order-confirmation__item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.order-confirmation__item img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-right: 10px;
  /* border-radius: 4px; */
  flex-shrink: 0;
}

.order-confirmation__item-info {
  flex: 1;
  min-width: 0;
  padding: 1px;
}

.order-confirmation__item-title {
  color: var(--primary-blue);
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
}

.order-confirmation__item-quantity {
  font-size: 12px;
  color: #555;
  margin-top: 5px;
  margin-bottom: 0px;
}

.order-confirmation__item-total {
  color: var(--primary-red);
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  min-width: 100px;
  flex-shrink: 0;
}

.order-confirmation__pricing {
  text-align: right;
  padding-top: 10px;
}



.order-confirmation__pricing p {
  margin: 4px 0;
  font-size: 15px;
}

.order-confirmation__pricing h3 {
  font-size: 18px;
  font-weight: 500;
  color: var(--primary-red);
}


.value_content_bank {
  font-size: 20px;
  font-weight: 600;
  color: #e12222;
}

.title_content_bank {
  font-size: 15px;
  font-weight: 500;
}

.box_content_bank {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  margin-top: 10px;
}

.btn_completed span {
  font-size: 18px;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 8px;
  /* border: 1px solid gray; */
  background: #0459aa;
  color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
}