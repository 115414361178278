.news-detail-container-title {
  text-align: center;
  font-weight: 700;
  color: var(--primary-blue);
}



.news-detail-container-conetent div .Table {
  width: 100% !important;
}

.news-detail-container-conetent div ul {
  list-style-type: disc;
}