.contact-container {
  margin-top: 32px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
}

.contact-container h2 {
  position: relative;
  text-align: center;
  font-size: 28px;
  color: var(--primary-blue);
  font-weight: 600;
  letter-spacing: -1px;
  margin-top: 30px;
}

.contact-container h2::after {
  content: "";
  display: block;
  width: 6%;
  height: 3px;
  background: var(--primary-color);
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
}

.contact-map {
  padding-left: 0;
  padding-right: 0;
  margin: 28px 0;
}

.contact-info {
  background-color: var(--primary-color);
  padding: 20px;
  font-size: 14px;
  color: var(--text-gray);
}

.contact-info__icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-blue);
}

.quote-form {
  max-width: 600px;
  margin: 0 auto;
}

.quote-form h2 {
  position: relative;
  text-align: center;
  font-size: 28px;
  color: var(--primary-blue);
  font-weight: 600;
  letter-spacing: -1px;
  margin-top: 30px;
}

.quote-form h2::after {
  content: "";
  display: block;
  width: 20%;
  height: 3px;
  background: var(--primary-color);
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
}

.contact-form__content {
  font-size: 14px;
  text-align: center;
  margin: 16px 0;
  color: var(--text-gray);
}

.contact-form__box {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.contact-form__input {
  flex: 1;
}

.contact-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 14px;
  color: var(--text-gray);
  width: 24%;
}

.contact-form__box label span {
  color: var(--primary-red);
  font-weight: 300;
}

.error_msg {
  font-size: 14px;
  color: var(--primary-red);
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 0px;
  font-size: 14px;
}

.contact-form__input select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 0px;
  font-size: 14px;
}

.contact-form input:focus,
.contact-form textarea:focus,
.contact-form__input select:focus {
  border-color: var(--primary-blue);
}

.contact-form__submit {
  font-size: 16px;
}

.contact-form__box .error {
  font-size: 14px;
  color: var(--primary-red);
}
