.form-forget-action {
    display: flex;
    justify-content: flex-end;
}

.send-and-login-btn {
    background-color: var(--primary-blue);
    color: #fff ;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 40px;
    border-radius: 5px;
    padding: 5px 10px;
    margin-right: 5px;
    cursor: pointer;
}

.send-and-login-btn:hover {
    opacity: 0.7;
}