.introduce-main-container {
    margin-top: 1rem;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 15px;
}

.introduce-main-box {
    display: flex;
}

.introduce-main-list {
    display: flex;
    flex-direction: column;
    font-size: 15px;
}

.Introduce-item {
    margin: 5px;
    padding: 10px 10px;
    background-color: #f3f3f3;
    border-radius: 5px;
    width: 28rem;
    color: #000;
    font-weight: 600;
}

.Introduce-item.active {
    color: var(--primary-red);
}

.Introduce-item.active:hover {
    color: var(--primary-red) !important;
}

.introduce-main-content {
    margin-left: 10px;
    margin-top: 10px;
    border-radius: 10px;
    width: 100%;
    background-color: #f3f3f3;
    padding: 10px 15px;
}

.introduce-company-container p{
    margin-bottom: 0;
    font-size: 14px;
}

.introduce-company-img {
    width: 100%;
}

.introduce-company-container div {
    font-size: 14px;
}

.introduce-company-container b {
    font-size: 14px;
}

.partner-position-container div{
    font-size: 14px;
}

.partner-position-box {
    width: 100%;
    margin: 10px 0;
}

.partner-position-img {
    width: 100%;
    height: 65rem;
}

.news-detail-container-conetent ul{
    display: block;
}

.no-introduce {
    color: #888;
    font-weight: 700;
    font-size: 20px !important;
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .introduce-main-box {
        display: block;
    }
    .introduce-main-list {
        font-size: 15px;
        display: flex;
        flex-direction: row;
        width: 100%;
        overflow: auto;
    }

    .introduce-main-list::-webkit-scrollbar {
        display: none;
    }

    .introduce-main-content {
        margin-left: 0;
        margin-top: 10px;
        border-radius: 10px;
        width: 100%;
        background-color: #f3f3f3;
        padding: 10px 15px;
    }
    .Introduce-item {
        margin: 10px 5px;
        min-width: fit-content;
        height: 50px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;
        background-color: #f3f3f3;
        border-radius: 5px;
        font-weight: 600;
    }
}
@media only screen and (min-width: 767px) and (max-width: 1023px) {
    .introduce-main-box {
        display: block;
    }
    .introduce-main-list {
        font-size: 15px;
        display: flex;
        flex-direction: row;
        width: 100%;
        overflow: auto;
    }

    .introduce-main-content {
        margin-left: 0;
        margin-top: 10px;
        border-radius: 10px;
        width: 100%;
        background-color: #f3f3f3;
        padding: 10px 15px;
    }
    .Introduce-item {
        margin: 10px 5px;
        min-width: fit-content;
        height: 50px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;
        background-color: #f3f3f3;
        border-radius: 5px;
        font-weight: 600;
    }
}