.header-sidebar-container {
    /* background-color: var(--primary-color); */
    padding-top: 20px;
}

.header-sidebar-title {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    color: var(--primary-blue);
    text-transform: uppercase;
}

.header-cate-items {
    padding: 6px 15px;
    color: var(--primary-red);
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: 1px solid #cacaca;
}

