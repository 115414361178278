.title-product {
  width: 100%;
  text-align: center;
  color: red;
}

.txt-brif-productHot {
  font-size: 12px;
  color: #000;
  font-weight: 500;
  display: -webkit-box;
  min-height: 2rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  line-height: 1rem;
}

.product-hot-banner {
  position: absolute;
  width: 60px;
  top: -14px;
  left: 4px;
}

.icon-inside-product {
  width: 32px;
  height: 32px;
  background-color: rgba(0, 15, 255, 0.5);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.icon-inside-product svg {
  width: 17px;
  height: 17px;
}

.bg_btn_compare {
  background-color: #ffd400;
}

.bg_btn_product_cart {
  background-color: #0459aa;
}

.bg_btn_see_detail {
  background-color: #5dbb81;
}

.img-product-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15rem;
  padding: 20px 0;
}

.img-product-box span {
  width: 100%;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.icon-inside-product:hover {
  opacity: 0.7;
}

.icon-compare-product {
  font-size: 18px !important;
}

.txt-price-productHot {
  color: var(--primary-red);
  cursor: pointer;
  font-size: 15px;
  float: left;
  width: 100%;
  font-weight: bold;
  display: -webkit-box;
  min-height: 1rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  line-height: 1rem;
}

.box-buy-now {
  justify-content: center;
}

.txt-priceOld-productHot {
  text-decoration: line-through;
  color: #000;
  font-size: 110%;
  margin-right: 2px;
}

.box-btn-producthot {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-add-cart {
  width: 45%;
  color: #fff;
  border-radius: 5px;
  background-color: var(--primary-blue);
}

.btn-buy {
  color: #fff;
  border-radius: 5px;
  background-color: var(--primary-red);
  display: flex;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 10px;
  width: 100%;
  height: 35px;
  justify-content: center;
  align-items: center;
}

.btn-buy p {
  margin: 0;
}

.btn-buy-title {
  color: #fff;
}

.btn-buy-title:hover {
  color: #fff;
}

.card-producthot-product {
  min-width: 20% !important;
  height: 100% !important;
  cursor: pointer;
}

.img-product {
  max-width: 100%;
  max-height: 100%;
  transition: all 0.4s ease-in-out 0s !important;
}

.img-product:hover {
  transition: all 0.4s ease-in-out 0s;
  transform: rotateY(180deg) !important;
}

.sale {
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.topsale {
  display: flex;
  position: absolute;
  bottom: 5px;
  height: fit-content;
  transform: translateX(50%);
  right: 50%;
}

.discount {
  background-color: var(--primary-red);
  color: #fff;
  width: 55px;
  justify-content: center;
  font-weight: 700;
  align-items: center;
  display: flex;
  height: 29px;
  font-size: 14px;
  /* padding: 2px 5px; */
  border-radius: 4px;
}

.discount p {
  margin: 0;
}

.icon-inside-product-midle {
  margin: 0 10px;
}

/* .Notification {
    z-index: 1005 !important;
} */
@media only screen and (max-width: 767px) {
  .txt-priceOld-productHot {
    text-decoration: line-through;
    color: #000;
    font-size: 12px;
    margin-right: 7px;
  }

  .txt-price-productHot {
    color: var(--primary-red);
    cursor: pointer;
    font-size: 16px;
    float: left;
    width: 110%;
    font-weight: bold;
    display: -webkit-box;
    max-height: 1.8rem;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 1;
    line-height: 3rem;
  }

  .box-btn-producthot {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-buy {
    color: #fff;
    border-radius: 5px;
    background-color: var(--primary-red);
    display: flex;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 12px;
    width: 100%;
    height: 35px;
    justify-content: center;
    align-items: center;
  }

  .icon-inside-product {
    width: 35px;
    height: 35px;
    /* background-color: #424242a8; */
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  .card-producthot-product {
    min-width: 16vw !important;
    height: 100% !important;
    cursor: pointer;
  }

  .img-product-box {

    height: 180px;
  }

  .topsale {
    display: flex;
    position: absolute;
  }

  .icon-inside-product-midle {
    margin: 0 10px;
  }

  .product-hot-banner {
    position: absolute;
    width: 60px;
    top: -8%;
    left: 2%;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .txt-priceOld-productHot {
    text-decoration: line-through;
    color: #000;
    font-size: 12px;
    margin-right: 7px;
  }

  .txt-price-productHot {
    color: var(--primary-red);
    cursor: pointer;
    font-size: 16px;
    float: left;
    width: 110%;
    font-weight: bold;
  }

  .txt-price-productHot {
    color: var(--primary-red);
    cursor: pointer;
    font-size: 16px;
    float: left;
    width: 110%;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 1;
  }

  .box-btn-producthot {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-buy {
    color: #fff;
    border-radius: 5px;
    background-color: var(--primary-red);
    display: flex;
    margin-bottom: 10px;
    font-weight: 700;
    width: 150px;
    font-size: 14px;
    width: 100%;
    height: 35px;
    justify-content: center;
    align-items: center;
  }
}

.container_card_product {
  margin-top: 5px;
  position: relative;
}

.box_price_reduction {
  position: absolute;
  z-index: 1;
  top: -3px;
  left: -3px;
}

.box_price_reduction img {
  width: 80px;
  height: 35px;
}

.value_reduction {
  position: absolute;
  top: 4px;
  left: 8px;

  color: white;
  font-size: 14px;
  font-weight: 600;
}