.register-form__wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 32px auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

.register-form__heading {
  position: relative;
  text-align: center;
  font-size: 28px;
  color: var(--primary-blue);
  font-weight: 600;
  letter-spacing: -1px;
  /* margin-top: 30px; */
  text-transform: uppercase;
  margin-bottom: 10px;
}

.register-form__heading::after {
  content: "";
  display: block;
  width: 10%;
  height: 3px;
  background: var(--primary-color);
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
}

.register-form__subheading {
  text-align: center;
  font-style: italic;
  font-size: 16px;
  font-weight: 500;
  color: var(--text-gray);
}

.register-form__container {
  margin-top: 20px;
}

.register-form__container h5 {
  color: #333;
}

.register-form__input label {
  font-size: 15px;
}

.register-form__input {
  margin-bottom: 10px;
}

.register-form__input input {
  width: 100%;
  padding: 7px;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  font-size: 14px;
}

.register-form__input input:focus {
  outline: 2px solid #2684ff;
  border: none;
}

.user-form,
.company-form {
  padding: 0 20px;
}

.company-form {
  border-left: 1px solid rgb(228, 229, 240);
}

.register-form__input .error {
  color: red;
  font-size: 13px;
  margin-top: 5px;
}

.register-form__submit-box {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 
  gap: 10px; 
  margin-top: 20px; 
}

.register-form__policy {
  text-align: center; 
  color: var(--text-gray);
}