.technology-corner-container {
  margin-top: 1rem;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 15px;
}

.technology-corner-title {
  background-color: var(--primary-color);
  color: var(--primary-red);
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 5px;
  height: 40px;
  border-radius: 5px;
}

.box_time_news span {
  font-size: 13px;
  color: gray;
}

.box_time_news svg {
  color: gray;
}

.card-text {
  font-size: 14px;
  color: #434657;
}

.technology-corner-container-box {
  display: flex;
}

.technology-corner-container-left {
  width: 45%;
}

.technology-corner-list {
  display: flex;
  flex-direction: column;
  font-size: 15px;
}

.technology-corner-item {
  margin: 5px;
  padding: 10px 10px;
  /* width: 100%; */
  background-color: #f3f3f3;
  border-radius: 5px;
  font-weight: 600;
}

.text-active {
  color: var(--primary-red);
}

.text-active:hover {
  color: var(--primary-red);
}

.technology-corner-product-promotion {
  background-color: #f3f3f3;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  height: 300px;
  overflow: hidden;
}

.technology-corner-product-promotion .product-promotion-list {
  transform: translateY(100%);
  animation: scroll-up 30s linear infinite;
  height: 100%;
  width: auto;
}

.box_auto_amination {
  overflow: hidden;
}

@keyframes scroll-up {
  0% {
    transform: translateY(70%);
  }

  100% {
    transform: translateY(-100%);
  }
}

.technology-corner-product-promotion-title {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--primary-blue);
}

.technology-corner-container-content {
  margin-left: 10px;
  margin-top: 6px;
  border-radius: 10px;
  width: 100%;
  background-color: #f3f3f3;
  padding: 10px 15px;
}

.product-promotion-items {
  width: 100%;
  margin-top: 6px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #888;
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: 10px;
}

.product-promotion-img {
  width: 50px;
  height: 45px;
}

.product-promotion-desc-title {
  font-size: 12px;
  font-weight: 600;
  display: -webkit-box;
  min-height: 3rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  line-height: 1.5rem;
}

.product-promotion-desc-title:hover {
  color: var(--primary-red);
}

.product-promotion-desc {
  margin-left: 5px;
}

.product-promotion-desc-price {
  font-size: 12px;
  font-weight: 700;
  color: var(--primary-red);
}

.product-promotion-desc-status {
  font-size: 12px;
}

.status-product {
  color: green;
}

.technology-corner-product-promotion-box-moblie {
  display: none;
}

/* .product-promotion-list {
    height: 50vh;
    overflow-y: scroll;
} */

.no-news-title {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: #888;
}

@media only screen and (max-width: 767px) {
  .technology-corner-container-box {
    display: block;
  }

  .technology-corner-container-left {
    width: 100%;
  }

  .technology-corner-list {
    font-size: 15px;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
  }

  .technology-corner-content {
    margin-left: 0;
    margin-top: 10px;
    border-radius: 10px;
    width: 100%;
    background-color: #f3f3f3;
    padding: 10px 15px;
  }

  .technology-corner-item {
    margin: 10px 5px;
    min-width: fit-content;
    height: 50px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    background-color: #f3f3f3;
    border-radius: 5px;
    font-weight: 600;
  }

  .technology-corner-product-promotion-box-desktop {
    display: none;
  }

  .technology-corner-product-promotion-box-moblie {
    display: block;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .technology-corner-container-box {
    display: block;
  }

  .technology-corner-container-left {
    width: 100%;
  }

  .technology-corner-list {
    font-size: 15px;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
  }

  .technology-corner-container-content {
    margin-left: 0;
    margin-top: 10px;
    border-radius: 10px;
    width: 100%;
    background-color: #f3f3f3;
    padding: 10px 15px;
  }

  .technology-corner-item {
    margin: 10px 5px;
    min-width: fit-content;
    height: 50px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    background-color: #f3f3f3;
    border-radius: 5px;
    font-weight: 600;
  }

  .technology-corner-product-promotion-box-desktop {
    display: none;
  }

  .technology-corner-product-promotion-box-moblie {
    display: block;
  }
}
