.fix-quantity-title {
    text-align: center;
    font-weight: 600 !important;
    font-size: 18px !important;
}

.fix-quantity-content {
    margin-top: 10px;
    padding: 5px 10px;
    border: 1px solid rgb(228, 229, 240);
    border-radius: 5px;
}

.fix-quantity-image {
    width: 70px;
    height: 70px;
    border: 1px solid rgb(228, 229, 240);
}


.fix-quantity-content-title {
    width: 80%;
    font-weight: 600;
    font-size: 12px;
    padding: 2px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.fix-quantity-product-name {
    font-weight: 700;
}

.fix-quantity-price {
    font-size: 18px;
    font-weight: 700;
    color: var(--primary-red);
}

.fix-quantity-product {
    opacity: 1;
    height: 3rem;
    width: 9rem;
    border-radius: 5px;
    font-size: 18px;
    margin-left: 5px;
    background: rgb(248, 248, 252);
    border: 1px solid rgb(228, 229, 240);
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: space-around;
}

.fix-quantity-product p {
    margin-bottom: 0;
}