.box-new-container {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-bottom: 10px;
}

.box-new-img {
  width: 220px;
  height: 130px;
  cursor: pointer;
  object-fit: cover;
}

.image.is-4by3 img {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.image img {
  display: block;
  height: auto;
  width: 100%;
}

.image {
  display: block;
  position: relative;
}

.image.is-4by3 {
  padding-top: 75%;
}

.box-new-desc {
  display: flex;
  flex-direction: column;
  height: 8rem;
  margin-left: 10px;
}

.box-new-desc-title {
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  display: -webkit-box;
  min-height: 3rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  line-height: 2.5rem;
}

.box-new-desc-title:hover {
  color: var(--primary-red);
}

.box-new-desc-date {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #555;
}

.box-new-desc-date-title {
  font-size: 14px;
  margin-left: 5px;
}

.box-new-desc-icon {
  font-size: 14px;
}

.box-new-description {
  font-size: 14px;
  margin-top: 5px;
  display: -webkit-box;
  min-height: 3rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  line-height: 1.5rem;
}

@media only screen and (max-width: 767px) {
  .technology-corner-container-content {
    margin-left: 0;
    margin-top: 6px;
    border-radius: 10px;
    width: 100%;
    background-color: #f3f3f3;
    padding: 10px 15px;
  }

  .box-new-desc {
    display: flex;
    flex-direction: column;
    height: 9rem;
    margin-left: 10px;
  }

  .box-new-img {
    width: 100px;
    height: 60px;
  }

  .box-new-desc-title {
    font-size: 12px;
    font-weight: 700;
    display: -webkit-box;
    min-height: 3rem;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    line-height: 1.5rem;
  }

  .box-new-desc-icon {
    font-size: 12px;
  }

  .box-new-desc-date-title {
    font-size: 12px;
    margin-left: 5px;
  }

  .box-new-description {
    font-size: 10px;
    margin-top: 5px;
    display: -webkit-box;
    min-height: 3rem;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    line-height: 1.5rem;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .technology-corner-container-content {
    margin-left: 0;
    margin-top: 6px;
    border-radius: 10px;
    width: 100%;
    background-color: #f3f3f3;
    padding: 10px 15px;
  }

  .box-new-img {
    width: 200px;
    height: 100px;
  }

  .box-new-desc-title {
    font-size: 16px;
    font-weight: 700;
  }

  .box-new-desc-icon {
    font-size: 12px;
  }

  .box-new-description {
    font-size: 12px;
    margin-top: 5px;
  }
}