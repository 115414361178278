.pickup-container {
  padding: 10px;
}

.pickup-address {
  background-color: #f8f8fc;
  width: 100%;
  border-radius: 5px;
  padding: 15px;
}

.pickup-address p {
  margin-bottom: 0;
}

.pickup-info {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
}

.pickup-input-container {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  gap: 20px;
}

.pickup-input {
  padding: 10px;
  outline: none;
  width: 20rem;
  padding: 10px;
  border: 1px solid rgb(228, 229, 240);
  border-radius: 5px;
}

.attention {
  color: var(--primary-red);
}

.pickup-input-lable {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
}

@media only screen and (max-width: 767px) {
  .pickup-input-container {
    display: block;
    margin-top: 10px;
  }

  .pickup-input-container .pickup-input-item:nth-child(2) {
    margin: 10px 0;
  }

  .pickup-input {
    margin-top: 4px;
    padding: 2px 10px;
    height: 35px;
    width: 100%;
    outline-color: var(--primary-red);
    outline-width: 0.5px !important;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .pickup-input-container {
    display: flex;
    margin-left: 8px;
    margin-top: 10px;
  }

  .note-container {
    margin-left: 5px;
    margin-top: 7px;
    padding: 10px;
    background-color: #fff;
    font-size: 14px;
    overflow: hidden;
    border-radius: 5px;
  }
}

@media only screen and (min-width: 1024) {
  .pickup-input {
    padding: 10px;
    width: 150px;
    outline: none;
    padding: 10px;
    border: 1px solid rgb(228, 229, 240);
    border-radius: 5px;
  }
}
