@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --primary-color: #ffd400;
  --primary-red: #e20000;
  --primary-blue: #0459aa;
  --text-gray: #666;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f6f8;
}

code {
  font-family: "Geomanist", sans-serif;
}

a {
  text-decoration: none;
}


.box_menu {
  padding: 10px;
  background: white;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: sticky;
  top: 105px;
}

.item_menu span {
  font-size: 14px;
  color: #686868;
  font-weight: 500;
}

.item_menu svg {
  font-size: 22px;
  color: #4a4a4a;
}

.item_menu {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 10px;
}


.menu_active {
  border: 1px solid var(--primary-red);
  border-radius: 5px;
}

.box_form_info_user {
  padding: 10px;
  background: white;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}


/* CSS COMPONENT CARD ITEM ORDER START  */

.item_card_order {
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #c3d0fd;
}

.box_image_card_item_order {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #c3d0fd;
  border-radius: 8px;
}

.item_infor_card_item_order {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name_card_item_order {
  font-size: 16px;
  font-weight: 500;
  color: rgb(51, 51, 51);
}

.text_info_basic_card_item_order {
  font-size: 14px;
  font-weight: 400;
  color: rgb(130, 134, 158);
}

.name_web {
  font-size: 15px;
  color: rgb(23, 130, 230);
  font-weight: 500;
}

.price_card_item_order {
  font-size: 14px;
  font-weight: 600;
  color: rgb(67, 70, 87);
}

.box_image_card_item_order img {
  width: 100px;
  height: 80px;
}

/* CSS COMPONENT CARD ITEM ORDER END  */

/* CSS COMPONENT BREADCRUMBS NEW START  */
.item_breadcrumb {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.item_breadcrumb a {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;

}

.item_breadcrumb svg {
  font-size: 20px;
}


.container_breadcrumb_new {
  margin: 0px 0px 10px 0px;
}

/* CSS COMPONENT BREADCRUMBS NEW END  */



/* CSS COMPONENT CARD ITEM ORDER START  */


@media (min-width:320px) and (max-width: 640px) {

  .item_name_price {
    flex-flow: column;
    align-items: end;
  }


  .box_image_card_item_order img {
    width: 100%;
    height: 100%;
  }


  .box_image_card_item_order {
    padding: 2px;
  }

  .item_card_order {
    align-items: center;
  }
}


/* CSS COMPONENT CARD ITEM ORDER END  */



.text_genaral_two_line_2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}


.container_banner_advertising_right {
  position: fixed;
  top: 20%;
  width: -moz-fit-content;
  width: fit-content;
  right: 0;
}

.container_banner_advertising_left {
  position: fixed;
  top: 20%;
  width: -moz-fit-content;
  width: fit-content;
  left: 0;
}

@media (min-width:1500px) and (max-width: 1600px) {

  .container_banner_advertising_left img,
  .container_banner_advertising_right img {
    width: 130px;
  }

}


@media (min-width:1400px) and (max-width: 1499px) {

  .container_banner_advertising_left img,
  .container_banner_advertising_right img {
    width: 68px;
  }

}

.container_lightbox_gallery .yarl__container,
.container_lightbox_gallery .yarl__thumbnails_container,
.container_lightbox_gallery .yarl__thumbnails_thumbnail {
  background: rgba(0, 0, 0, .45) !important;
}

.container_lightbox_gallery .yarl__thumbnails_bottom .yarl__thumbnails_vignette,
.container_lightbox_gallery .yarl__thumbnails_top .yarl__thumbnails_vignette {
  background: unset !important;
}