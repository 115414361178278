 .container_account {
     padding-top: 15px;
 }






 .box_avatar_account img {
     width: 70px;
     height: 70px;
 }




 .space_select_address .ant-space-item {
     width: 100% !important;
 }

 .space_info_client_form .ant-space-item {
     width: 50%;
 }

 .title_address {
     margin-bottom: 10px;
     font-size: 17px;
     font-weight: 500;
 }

 .box_form_account {
     padding: 20px;
 }


 .box_status_order {
     display: flex;
     align-items: center;
     background: white;
     border-radius: 8px;
     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
     width: fit-content;
 }


 .item_status {
     padding: 8px 10px;
     font-size: 14px;
     font-weight: 400;
     color: rgb(153, 153, 153);
     cursor: pointer;
 }

 .item_status:hover {
     color: rgb(20, 53, 195);
 }

 .text_title_common {
     font-size: 16px;
     font-weight: 500;
     color: #3d3d3d;
 }



 .box_list_order th {
     font-size: 12px;
     font-weight: 500 !important;
 }

 .box_list_order {
     background: white;
     padding: 15px;
     border-radius: 8px;
     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
 }


 .col_name_product {
     width: 280px;
 }

 .item_row_product {
     cursor: pointer;
 }

 .item_row_product img {
     transition: all 0.6s ease;
 }

 .item_row_product:hover img {
     transform: scale(1.1);
     transition: all 0.6s ease;
     border-radius: 8px;
 }

 .code_item_order {
     cursor: pointer;
     font-size: 14px;
     color: rgb(4, 127, 255) !important;
     font-weight: 500;
 }

 .value_item_order {
     font-size: 14px;
 }

 .name_product_manager {
     font-weight: 500;
 }

 .total_order_manager {
     font-size: 15px;
     font-weight: 500;
     color: #007bff;
 }

 .value_item_status {
     font-size: 13px;
 }

 .box_empty_product_manage_order {
     display: flex;
     flex-flow: column;
     gap: 5px;
     justify-content: center;
     align-items: center;
 }

 .box_empty_product_manage_order span {
     font-size: 14px;
     font-weight: 400;
 }

 .box_empty_product_manage_order img {
     width: 150px;
     height: 150px;
 }

 .box_loading_orders {
     padding: 15px;
     text-align: center;
 }

 .item_status_active {
     color: rgb(20, 53, 195) !important;
 }

 .bottom_content_detail_order,
 .middle_content_detail_order,
 .item_top_content_order {
     background: white;
     padding: 15px;
     border-radius: 8px;
     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
 }

 .top_content_detail_order {
     display: flex;
     justify-content: space-between;
     gap: 10px;
     margin-top: 10px;
 }

 .title_top_content_order {
     display: block;
     margin-bottom: 5px;
     color: #333333;
     font-weight: 600;
     font-size: 15px;
 }

 .bottom_content_detail_order .item_info_top_content {
     gap: 100px;
 }

 .title_top_content {
     font-size: 15px;
     font-weight: 600;
     margin-right: 5px;
 }

 .value_top_content {
     font-size: 14px;
 }


 .value_total_money {
     color: rgb(235, 33, 1);
     font-size: 20px;
     font-weight: 600;
 }

 .text_vat {
     display: block;
     font-size: 11px;
     font-weight: 300;
     line-height: 12px;
     color: rgb(130, 134, 158);
 }

 .box_method_payment {
     padding: 7px 0px;
     border-bottom: 1px solid #c3d0fd;
 }

 .box_method_payment_2 {
     padding: 8px 0px;
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .btn_repurchase {
     display: flex;
     justify-content: end;
 }

 .btn_repurchase span {
     cursor: pointer;
     font-size: 15px;
     padding: 5px 10px;
     border: 1px solid #c3d0fd;
     line-height: normal;
     font-weight: 500;
     border-radius: 8px;
     background: #5bc0eb;
     color: white;
 }

 .box_form_change_pass form {
     min-width: 600px
 }

 .btn_repurchase:hover span {
     background: #0077b6;
     color: white;
 }


 .box_item_data {
     display: flex;
     align-items: center;
     gap: 5px;
     justify-content: space-around;
 }

 .box_data {
     width: 100%;
     border-radius: 8px;
     background: white;
     padding: 15px 10px;
     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
     display: flex;
     align-items: center;
     justify-content: center;
 }

 .box_title span {
     font-size: 18px;
     font-weight: 500;
     color: rgb(67, 70, 87);
 }

 .value_data span {
     font-size: 28px;
     color: rgb(67, 70, 87);
     font-weight: 600;
 }

 .container_data {
     display: flex;
     align-items: center;
     gap: 8px;
     height: 100%;
 }

 .box_filter_chart {
     width: 100%;
 }

 .box_filter_chart .ant-space-item {
     width: 100%;
 }


 @media (min-width:320px) and (max-width: 640px) {

     .box_form_account {
         padding: 5px;
     }

     .container_data {
         flex-wrap: wrap;
         height: fit-content;
     }

     .box_list_order .table th {
         width: fit-content !important;
         white-space: nowrap;
     }


     .box_form_change_pass {
         width: 100%;
     }

     .box_form_change_pass form {
         min-width: unset;
     }

     .top_content_detail_order {
         flex-flow: column;
     }
 }


 @media (min-width:641px) and (max-width: 960px) {

     .container_data {
         flex-wrap: wrap;
         height: unset;
     }

     .box_form_account {
         padding: 0px;
     }

     .box_form_change_pass form {
         min-width: unset;
     }

     .box_form_change_pass {
         width: 100%;
     }
 }