.warranty-policy-container {
    margin-top: 1rem;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 15px;
}


.warranty-policy-container-box {
    display: flex;
}

.warranty-policy-list {
    display: flex;
    flex-direction: column;
    font-size: 15px;
}

.warranty-policy-item {
    margin: 5px;
    padding: 10px 10px;
    background-color: #f3f3f3;
    border-radius: 5px;
    font-weight: 600;
}

.image-qr {
    width: 70%;
}

.text-active {
    color: var(--primary-red);
}

.text-active:hover {
    color: var(--primary-red);
}

.warranty-policy-container-content {
    margin-left: 10px;
    margin-top: 10px;
    border-radius: 10px;
    width: 100%;
    background-color: #f3f3f3;
    padding: 10px 15px;
}

.text-title {
    color: var(--primary-blue);
}

.text-size {
    font-size: 15px;
}

.text-note {
    color: var(--primary-red);
}

.icon-star {
    color: #ebcd20;
    font-size: 16px;
    margin-right: 5px;
}

@media only screen and (max-width: 767px) {
    .warranty-policy-container-box {
        display: block;
    }

    .warranty-policy-list {
        font-size: 15px;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
    }

    .warranty-policy-container-content {
        margin-left: 0;
        margin-top: 10px;
        border-radius: 10px;
        width: 100%;
        background-color: #f3f3f3;
        padding: 10px 15px;
    }

    .warranty-policy-item {
        margin: 10px 5px;
        min-width: fit-content;
        height: 50px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;
        background-color: #f3f3f3;
        border-radius: 5px;
        font-weight: 600;
    }

    .image-qr {
        width: 100%;
    }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
    .warranty-policy-container-box {
        display: block;
    }

    .warranty-policy-list {
        font-size: 15px;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
    }

    .warranty-policy-container-content {
        margin-left: 0;
        margin-top: 10px;
        border-radius: 10px;
        width: 100%;
        background-color: #f3f3f3;
        padding: 10px 15px;
    }

    .warranty-policy-item {
        margin: 10px 5px;
        min-width: fit-content;
        height: 50px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;
        background-color: #f3f3f3;
        border-radius: 5px;
        font-weight: 600;
    }

    .image-qr {
        width: 70%;
    }
}